import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import {
  postLogin,
  resetEmail,
  tokenValidation,
  passwordReset,
} from '../../redux/submitLoginSlice';
import styles from './Login.module.css';
import { imgBackground, logoDark, iconEye, iconMail } from '../../assets';

const Signup = () => {
  const navigate = useNavigate();
  let { action, token } = useParams();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const handlePasswordVisibility = () => setShowPassword(!showPassword);

  // useEffect(() => {
  //   const validateToken = async () => {
  //     if (token && action === 'reset_password') {
  //       navigate(`/client/login/new-password/${token}`);
  //       return;
  // const resultAction = await dispatch(tokenValidation(token));
  // if (resultAction.type === 'login/tokenValidation/fulfilled') {
  //   // reset();
  //   navigate(`/client/login/new-password/${token}`);
  // } else {
  //   navigate(`/client/login/invalid-token`);
  // }
  //     }
  //   };

  //   validateToken();
  // }, [token, dispatch, navigate, action]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    watch,
    reset,
    trigger,
    setError,
  } = useForm({
    defaultValues: {
      email: '',
      password: '',
    },
  });
  const onSubmit = async (data) => {
    const resultAction = await dispatch(postLogin(data));
    if (resultAction.type === 'login/postData/fulfilled') {
      //   reset();
      navigate('/client/dashboard');
    }
  };

  const validateAndReset = async () => {
    const result = await trigger(['email']);
    if (!result) return;
    // () => navigate('/client/login/email-sent')
    const email = getValues('email');
    const resultAction = await dispatch(resetEmail({ email }));
    if (resultAction.type === 'login/resetEmail/fulfilled') {
      //   reset();
      navigate('/client/login/email-sent');
    } else if (resultAction.type === 'login/resetEmail/rejected') {
      setError('email', {
        type: 'manual',
        message: 'email dose not exist in our system. Please try again.',
      });
    }
  };

  const handleNewPassword = async () => {
    const newPassword = getValues('newPassword');
    const confirmPassword = getValues('confirmPassword');
    if (newPassword !== confirmPassword) {
      setError('confirmPassword', {
        type: 'manual',
        message: 'Passwords do not match',
      });
      return;
    }
    const resultAction = await dispatch(
      passwordReset({ password: newPassword, token })
    );

    if (resultAction.type === 'login/passwordReset/fulfilled') {
      //   reset();
      navigate('/client/login/enter');
    }
  };
  return (
    <section className={styles.signup}>
      <div className={styles.column1}>
        <div className={styles.logoWrapper}>
          <img src={logoDark} alt={'logoDark'} />
        </div>
        <img
          className={styles.heroLogo}
          src={imgBackground}
          alt={'background'}
        />
      </div>
      <div className={styles.column2}>
        {action === 'enter' && (
          <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
            <div>
              <h3 className={styles.header}>Log In</h3>
              <div>
                <label htmlFor='email' className={styles.labelTitle}>
                  Company Email
                </label>
                <div
                  style={{ position: 'relative' }}
                  className={styles.marginBottom}>
                  <input
                    className={styles.inputFieldWrap}
                    id='email'
                    {...register('email')}
                    placeholder='Enter Company Email'
                  />
                  {errors.email && <span>This field is required</span>}
                </div>
              </div>

              <div>
                <label htmlFor='password' className={styles.labelTitle}>
                  Password
                </label>
                <div
                  style={{ position: 'relative' }}
                  className={styles.marginBottom}>
                  <div className={styles.inputWrapper}>
                    <input
                      className={styles.inputFieldWrap}
                      id='password'
                      {...register('password')}
                      type={showPassword ? 'text' : 'password'}
                      placeholder='Enter password'
                    />
                    <img
                      className={styles.inputIcon}
                      src={iconEye}
                      alt={'iconEye'}
                      onClick={handlePasswordVisibility}
                    />
                  </div>

                  {errors.password && <span>This field is required</span>}
                  <div className={styles.forgotTextWrapper}>
                    <Link
                      className={styles.forgotText}
                      to='/client/login/forgot-password'>
                      Forgot Password?
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <button type={'submit'} className={styles.btnNext}>
              Submit
            </button>
            <div className={styles.line}></div>
            <span className={styles.textBelowLine}>Are you a new user?</span>
            <button
              className={styles.loginBtn}
              onClick={() => navigate('/client/signup/create-account')}>
              Sign Up
            </button>
          </form>
        )}
        {action === 'forgot-password' && (
          <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
            <div>
              <h3 className={styles.header}>Enter email</h3>
              <div>
                <label htmlFor='email' className={styles.labelTitle}>
                  Company Email
                </label>
                <div
                  style={{ position: 'relative' }}
                  className={styles.marginBottom}>
                  <input
                    className={styles.inputFieldWrap}
                    id='email'
                    {...register('email')}
                    placeholder='Enter Company Email'
                  />
                  {errors.email && <span>{errors.email.message}</span>}
                </div>
              </div>
            </div>
            <button
              onClick={validateAndReset}
              type={'button'}
              className={styles.btnNext}>
              Reset
            </button>
            <div className={styles.line}></div>
            <span className={styles.textBelowLine}>Are you a new user?</span>
            <button
              className={styles.loginBtn}
              type={'button'}
              onClick={() => navigate('/client/signup/create-account')}>
              Sign Up
            </button>
          </form>
        )}

        {action === 'email-sent' && (
          <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
            <div className={styles.iconWrapper}>
              <img
                className={styles.iconMail}
                src={iconMail}
                alt={'iconMail'}
              />
              <p className={styles.iconText}>
                We have sent you a link to reset
                <br /> password on your company email.
              </p>
            </div>
            <div className={styles.line}></div>
            <span className={styles.textBelowLine}>Are you a new user?</span>
            <button
              className={styles.loginBtn}
              onClick={() => navigate('/client/signup/create-account')}>
              Sign Up
            </button>
          </form>
        )}
        {action === 'invalid-token' && (
          <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
            <div className={styles.iconWrapper}>
              <img
                className={styles.iconMail}
                src={iconMail}
                alt={'iconMail'}
              />
              <p className={styles.iconText}>
                The token is invalid.
                <br /> Please try again.
              </p>
            </div>
            <div className={styles.line}></div>
            <span className={styles.textBelowLine}>Are you a new user?</span>
            <button
              className={styles.loginBtn}
              onClick={() => navigate('/client/login/enter')}>
              Log in
            </button>
          </form>
        )}
        {action === 'reset_password' && token && (
          <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
            <div>
              <h3 className={styles.header}>Reset Password</h3>

              <div>
                <label htmlFor='newPassword' className={styles.labelTitle}>
                  New Password
                </label>
                <div
                  style={{ position: 'relative' }}
                  className={styles.marginBottom}>
                  <div className={styles.inputWrapper}>
                    <input
                      className={styles.inputFieldWrap}
                      id='newPassword'
                      {...register('newPassword')}
                      type={showPassword ? 'text' : 'password'}
                      placeholder='Enter new password'
                    />
                    <img
                      className={styles.inputIcon}
                      src={iconEye}
                      alt={'iconEye'}
                      onClick={handlePasswordVisibility}
                    />
                  </div>

                  {errors.newPassword && <span>This field is required</span>}
                </div>
              </div>

              <div>
                <label htmlFor='confirmPassword' className={styles.labelTitle}>
                  Confirm Password
                </label>
                <div
                  style={{ position: 'relative' }}
                  className={styles.marginBottom}>
                  <div className={styles.inputWrapper}>
                    <input
                      className={styles.inputFieldWrap}
                      id='confirmPassword'
                      {...register('confirmPassword')}
                      type={showPassword ? 'text' : 'password'}
                      placeholder='Re-enter New Password'
                    />
                    <img
                      className={styles.inputIcon}
                      src={iconEye}
                      alt={'iconEye'}
                      onClick={handlePasswordVisibility}
                    />
                  </div>

                  {errors.password && <span>This field is required</span>}
                </div>
              </div>
            </div>
            <button
              type={'button'}
              // onClick={() => navigate('/client/signup/email-sent')}
              onClick={handleNewPassword}
              className={styles.btnNext}>
              Submit
            </button>
            <div className={styles.line}></div>
            <span className={styles.textBelowLine}>Are you a new user?</span>
            <button
              className={styles.loginBtn}
              onClick={() => navigate('/client/signup/create-account')}>
              Sign Up
            </button>
          </form>
        )}
      </div>
    </section>
  );
};

export default Signup;
