import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { get } from 'lodash';
import ThreeDots from '../../components/ThreeDots/ThreeDots';
import { fetchJobDetailData } from '../../redux/jobDetail';
import { openModal } from "../../redux/bulkUpload"
import { hideToast, showToast } from '../../redux/toastSlice';
import fullUrl from '../../utils/fullUrl';
import {
  iconSearch,
  iconPositive,
  iconBackGreen,
  iconUpload,
  iconCopy,
} from '../../assets';
import styles from './JobDetails.module.css';

const priorityMap = {
  notice_period: 'Notice Period',
  college_tier: 'Tier of College',
  min_experience: 'Min. Experience',
};

const Jobs = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { jobId } = useParams();
  const { data } = useSelector((state) => state.jobDetail);

  useEffect(() => {
    if (!jobId) return;
    dispatch(fetchJobDetailData({ job_id: jobId }));
  }, [dispatch, jobId]);

  const handleBulkUpload = () => {
    dispatch(openModal())
  };

  if (!data) return null;

  return (
    <section className={styles.jobsMainContent}>
      <header className={styles.jobsHeader}>
        <button className={styles.btnEmpty}>
          <img
            style={{ paddingRight: '4px' }}
            src={iconBackGreen}
            alt='go back'
          />
          <span>Back to All Jobs</span>
        </button>
        <div className={styles.jobsHeaderBox}>
          <div style={{ position: 'relative', marginRight: '1.6rem' }}>
            <img
              src={iconSearch}
              alt='search'
              style={{
                position: 'absolute',
                top: '50%',
                left: '10px',
                transform: 'translateY(-50%)',
              }}
            />
            {/* <img src={iconSearch} alt='search' /> */}
            <input
              className={styles.jobsSearchWrap}
              placeholder='Search for applicants and jobs'
              style={{ paddingLeft: '40px', textAlign: 'left' }}
            />
          </div>
          <button
            className={styles.jobsJobCta}
            onClick={() => {
              navigate('/client/create-job');
            }}>
            <img src={iconPositive} alt='positive' />
            <span className={styles.jobsJobText}>Create a job</span>
          </button>
        </div>
      </header>
      <div className={styles.roleWrapper}>
        <div className={styles.roleText}>{data?.role}</div>
        <div className={styles.ctaWrapper}>
          <button className={styles.btnEmpty} onClick={handleBulkUpload}>
            <img
              style={{ paddingRight: '4px' }}
              src={iconUpload}
              alt='go back'
            />
            <span>Bulk Upload Applicants</span>
          </button>
          <button
            className={styles.btnEmpty}
            onClick={() => {
              const embedLink = fullUrl(data?.job_url);
              navigator.clipboard
                .writeText(embedLink)
                .then(() => {
                  dispatch(
                    showToast({
                      message: 'Link copied!',
                      type: 'success',
                    })
                  );
                })
                .catch((err) => {
                  console.error('Could not copy text: ', err);
                });
            }}>
            <img style={{ paddingRight: '4px' }} src={iconCopy} alt='go back' />
            <span>Copy Embed Link</span>
          </button>
          <ThreeDots color={'#FF5757'} />
        </div>
      </div>

      <div className={styles.contentWrapper}>
        <div className={styles.columnLarge}>
          <div className={styles.rowWrapper}>
            <div className={styles.rowPadding}>
              <div className={styles.rowHeader}>
                <span className={styles.headerText}>Job Details</span>
              </div>
              <div className={styles.infoRow}>
                <div className={styles.infoColumn}>
                  <span className={styles.label}>Status</span>
                  <span className={styles.labelText + ' ' + styles.statusValue}>
                    {data.status ? 'Active' : 'Inactive'}
                  </span>
                </div>
                <div className={styles.infoColumn}>
                  <span className={styles.label}>Team</span>
                  <span className={styles.labelText}>{data.team}</span>
                </div>
              </div>

              <div className={styles.infoRow}>
                <div className={styles.infoColumn}>
                  <span className={styles.label}>Job Location</span>
                  <span className={styles.labelText}>{data.job_location}</span>
                </div>
                <div className={styles.infoColumn}>
                  <span className={styles.label}>Job Type</span>
                  <span className={styles.labelText}>{data.job_type}</span>
                </div>
              </div>

              <div className={styles.infoRow}>
                <div className={styles.infoColumn}>
                  <span className={styles.label}>Level</span>
                  <span className={styles.labelText}>{data.level}</span>
                </div>
                <div className={styles.infoColumn}>
                  <span className={styles.label}>No. of Openings</span>
                  <span className={styles.labelText}>
                    {data.open_positions}
                  </span>
                </div>
              </div>

              <div className={styles.infoRow}>
                <div className={styles.infoColumn}>
                  <span className={styles.label}>Posted by</span>
                  <span className={styles.labelText}>{data.posted_by}</span>
                </div>
                <div className={styles.infoColumn}>
                  <span className={styles.label}>Posted on</span>
                  <span className={styles.labelText}>{data.posted_on}</span>
                </div>
              </div>
            </div>
          </div>

          <div className={styles.rowWrapper}>
            <div className={styles.rowPadding}>
              <div className={styles.rowHeader}>
                <span className={styles.headerText}>Job Criteria</span>
              </div>
              <div>
                {['High', 'Medium', 'Low']
                  .filter(
                    (priorityLevel) =>
                      data?.priority?.[priorityLevel.toLowerCase()]?.length > 0
                  )
                  .map((priorityLevel, index, array) => {
                    const isLastRow = index === array.length - 1;
                    return (
                      <React.Fragment key={priorityLevel}>
                        <div
                          className={
                            styles.priorityLevelText
                          }>{`${priorityLevel} Priority`}</div>
                        <div
                          className={`${styles.infoRow} ${!isLastRow ? styles.bottomExtraSpace : ''
                            }`}>
                          {data?.priority?.[priorityLevel.toLowerCase()].map(
                            (item) => (
                              <div
                                className={styles.infoColumn}
                                key={`${item.fieldName}${item.value}`}>
                                <span className={styles.label}>
                                  {priorityMap[item.fieldName]}
                                </span>
                                <span className={styles.labelText}>
                                  {item.value}
                                </span>
                              </div>
                            )
                          )}
                        </div>
                      </React.Fragment>
                    );
                  })}
              </div>
            </div>
          </div>

          <div className={styles.rowWrapper}>
            <div className={styles.rowPadding}>
              <div className={styles.rowHeader}>
                <span className={styles.headerText}>Job Description</span>
              </div>
              {/* <div className={styles.infoRowExtra}>
                <div className={styles.infoColumn}>
                  <span className={styles.label}>Role overview</span>
                  <div className={styles.labelText}>
                    {data?.job_description
                      ?.split(/[\n\r]+/)
                      .map((line, index) => (
                        <React.Fragment key={index}>
                          {line}
                          <br />
                        </React.Fragment>
                      ))}
                  </div>
                </div>
              </div> */}

              <div className={styles.infoRowExtra}>
                <div className={styles.infoColumn}>
                  <span className={styles.label}>Role overview</span>
                  <div className={styles.labelText}>
                    {data?.job_description?.split('\n').map((line, index) => (
                      <React.Fragment key={index}>
                        {line.replace(/\r/g, '')}{' '}
                        {/* Remove any \r characters left */}
                        <br />
                      </React.Fragment>
                    ))}
                  </div>
                </div>
              </div>

              <div className={styles.infoRowExtra}>
                <div className={styles.infoColumn}>
                  <span className={styles.label}>
                    Qualifications and Skillset
                  </span>
                  <div className={styles.labelText}>
                    {data?.kra_okr?.split('\n').map((line, index) => (
                      <React.Fragment key={index}>
                        {line.replace(/\r/g, '')} <br />
                      </React.Fragment>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.columnSmall}>
          <div className={styles.containerWrapper} style={{ display: 'none' }}>
            <div>
              <span>Applicants</span>
              <button>
                <span>View all</span>
                <img src={iconBackGreen} alt={'view'} />
              </button>
            </div>

            <div className={styles.container}>
              <div className={styles.item}>test</div>
              <div className={styles.item}>test</div>
              <div className={styles.item}>test</div>
              <div className={styles.item}>test</div>
              <div className={styles.item}>test</div>
              <div className={styles.item}>test</div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Jobs;
