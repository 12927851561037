function getApiBaseUrl() {
  if (window.location.hostname === 'localhost') {
    return 'http://localhost:5003';
  }
  return 'https://truehire.work';
}

export const DASHBOARD_API = `/dashboard`;
export const JOBS_API = `/jobs`;
export const JOBS_STATUS_UPDATE_API = `/update_job_status`;
export const CREATE_JOB_API = `/create_job_posting`;
export const SIGNUP_API = `/signup`;
export const VERIFY_OTP_API = `/verify`;
export const LOGIN_API = '/login';
export const FORGOT_PASSWORD_API = '/forgot_password';
export const RESET_PASSWORD_API = '/reset_password';
export const JOB_DETAIL_API = '/job-detail';
export const APPLY_API = '/job-apply';
