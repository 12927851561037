import { configureStore } from '@reduxjs/toolkit';
import dashboardReducer from './redux/dashboardSlice';
import modalReducer from './redux/modalSlice';
import bulkUploadSlice from './redux/bulkUpload';
import createJobSlice from './redux/createJobSlice';
import submitModalSlice from './redux/submitModalSlice';
import signUpSlice from './redux/submitSignupSlice';
import loginSlice from './redux/submitLoginSlice';
import jobsSlice from './redux/jobsSlice';
import jobDetailSlice from './redux/jobDetail';
import applySlice from './redux/applyJob';
import toastSlice from './redux/toastSlice';

export default configureStore({
  reducer: {
    dashboard: dashboardReducer,
    modal: modalReducer,
    createJob: createJobSlice,
    submitModal: submitModalSlice,
    signup: signUpSlice,
    login: loginSlice,
    jobs: jobsSlice,
    jobDetail: jobDetailSlice,
    apply: applySlice,
    toast: toastSlice,
    bulkUpload: bulkUploadSlice
  },
});
