import { createSlice } from '@reduxjs/toolkit';

const bulkModalSlice = createSlice({
    name: 'bulkUploadModal',
    initialState: { isOpen: false },
    reducers: {
        openModal: (state, action) => {
            state.isOpen = true;
        },
        closeModal: (state) => {
            state.isOpen = false;
        },
    },
});

export const { openModal, closeModal } = bulkModalSlice.actions;

export default bulkModalSlice.reducer;
