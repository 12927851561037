import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { JOB_DETAIL_API } from '../apiEndpoints';

function replaceEmptyValues(obj) {
  Object.keys(obj).forEach((key) => {
    if (obj[key] && typeof obj[key] === 'object') {
      replaceEmptyValues(obj[key]);
    } else if (obj[key] === null || obj[key] === undefined || obj[key] === '') {
      obj[key] = 'N/A'; // Replace null, undefined, or empty string with "N/A"
    }
  });
}

// Function to loop over sampleResponse and categorize tasks based on dynamic _priority keys
function categorizeTasks(response) {
  const categorizedTasks = {
    high: [],
    medium: [],
    low: [],
  };
  for (const key in response) {
    if (typeof response[key] === 'object') {
      for (const prop in response[key]) {
        if (prop.endsWith('_priority')) {
          const taskWithKey = { fieldName: key, ...response[key] };
          switch (response[key][prop]) {
            case 0:
              categorizedTasks.high.push(taskWithKey);
              break;
            case 1:
              categorizedTasks.medium.push(taskWithKey);
              break;
            case 2:
              categorizedTasks.low.push(taskWithKey);
              break;
            default:
              break;
          }
          break;
        }
      }
    }
  }
  return categorizedTasks;
}

export const fetchJobDetailData = createAsyncThunk(
  'jobDetail/fetchData',
  async (queryParams) => {
    const sampleResponse = await axios.get(JOB_DETAIL_API, {
      params: queryParams,
    });

    // const priority = categorizeTasks(sampleResponse);
    replaceEmptyValues(sampleResponse);
    return { ...sampleResponse };
    // return { ...sampleResponse, priority };
    // return response.data;
  }
);

export const jobDetailSlice = createSlice({
  name: 'jobDetail',
  initialState: { data: {}, status: 'idle', error: null },
  reducers: {
    saveDataJobDetail: (state, action) => {
      return action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchJobDetailData.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchJobDetailData.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload.data;
      })
      .addCase(fetchJobDetailData.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});
export const { saveDataJobDetail } = jobDetailSlice.actions;
export default jobDetailSlice.reducer;
