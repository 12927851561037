import React, { useEffect, useState, useRef, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import useMultipleClickOutside from '../../hooks/useClickOutside';
import usePdfThumbnail from '../../hooks/usePdfThumbnail';
import { yupResolver } from '@hookform/resolvers/yup';
import { get, isEmpty } from 'lodash';
import * as yup from 'yup';
import { debounce } from 'lodash';
import ThreeDots from '../../components/ThreeDots/ThreeDots';
import SubmitModal from '../../components/SubmitModal/SubmitModal';
import Loader from '../../components/Loader/Loader';
import { fetchApplyData, postApplyData } from '../../redux/applyJob';
import {
  iconSearch,
  iconPointDown,
  iconMinus,
  iconPlus,
  iconUploadBack,
  iconDelete,
  iconInfoDanger,
} from '../../assets';
import styles from './ApplyJob.module.css';
function convertUnixTimestampToDaysAgo(unixTimestamp) {
  const datePosted = new Date(unixTimestamp * 1000);
  const currentDate = new Date();
  const differenceInTime = currentDate.getTime() - datePosted.getTime();
  const differenceInDays = Math.floor(differenceInTime / (1000 * 3600 * 24));
  return `Posted ${differenceInDays} days ago`;
}

function transformKeys(dataTotransform) {
  const clientToServerKeyMap = {
    noticePEriodMax: 'notice_period',
    applicantName: 'applicant_name',
    college: 'college',
    educationalQualification: 'educational_qualification',
    emailAddress: 'applicant_email',
    currentRole: 'current_role',
    industryType: 'industry_type',
    mostRecentCompany: 'current_company',
    phoneNumber: 'phone_number',
    yearsOfExperience: 'years_experience',
    linkedIn: 'linkedin',
  };

  let newObject = {};
  for (let key in dataTotransform) {
    if (clientToServerKeyMap.hasOwnProperty(key)) {
      newObject[clientToServerKeyMap[key]] = dataTotransform[key];
    }
  }

  return newObject;
}
const ApplyJob = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { jobId } = useParams();
  const { data } = useSelector((state) => state.apply);
  const [currentDropdownStates, setCurrentDropdownStates] = useState('');
  const [isTyping, setIsTyping] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFileError, setSelectedFileError] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [changedFields, setChangedFields] = useState([]);

  const applyFormRef = useRef(null);
  const canvasRef = useRef(null);
  usePdfThumbnail(selectedFile, canvasRef, '', 17.6);

  useEffect(() => {
    if (!jobId) return;
    dispatch(fetchApplyData({ job_url: jobId }));
  }, [dispatch, jobId]);

  const validationSchema = yup.object().shape({
    emailAddress: yup
      .string()
      .required('This field is required')
      .email('Must be a valid email'),
    applicantName: yup.string().required('This field is required'),
    phoneNumber: yup.string().required('This field is required'),
    college: yup.string().required('This field is required'),
    industryType: yup.string().required('This field is required'),
    yearsOfExperience: yup.string().required('This field is required'),
    currentRole: yup.string().required('This field is required'),
  });

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    getValues,
    watch,
    trigger,
    reset,
  } = useForm({
    defaultValues: {
      educationalQualification: '',
      industryType: '',
      currentRole: '',
      yearsOfExperience: '',
      college: '',
      phoneNumber: '',
      applicantName: '',
      linkedIn: '',
    },
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (dataToTransform) => {
    if (!selectedFile) {
      setSelectedFileError('This is a required field');
      return;
    }
    setShowLoader(true);
    const readFileAsBase64 = (file) =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => resolve(reader.result.split(',')[1]);
        reader.onerror = (error) => reject(error);
      });
    try {
      const base64String = await readFileAsBase64(selectedFile);
      const newObj = transformKeys(dataToTransform);
      const resultAction = await dispatch(
        postApplyData({ ...newObj, resume: base64String, job_id: data.job_id })
      );
      setShowLoader(false);
      if (resultAction.type === 'apply/postData/fulfilled') {
        reset();
      }
    } catch (error) {
      console.error('Error reading file:', error);
      setShowLoader(false);
    }
  };

  const dropdownRefs = {
    educationalQualification: {
      ref: useRef(),
      id: '#searchEducationalQualification',
      ignoreId: 'educationalQualification',
    },
    industryType: {
      ref: useRef(),
      id: '#searchIndustryType',
      ignoreId: 'industryType',
    },
  };

  useMultipleClickOutside(
    Object.keys(dropdownRefs).map((key) => ({
      ref: dropdownRefs[key]['ref'],
      callback: () => setCurrentDropdownStates(''),
      ignoreId: key,
    }))
  );

  useEffect(() => {
    const dropdown = dropdownRefs[currentDropdownStates];
    if (dropdown && dropdown.ref.current) {
      const inputField = dropdown.ref.current.querySelector(dropdown.id);
      if (inputField) {
        inputField.focus();
      }
    }
  }, [currentDropdownStates]);

  const [selectedIndices, setSelectedIndices] = useState({
    educationalQualificationIndex: 0,
    industryTypeIndex: 0,
    noticePEriodMaxIndex: 0,
    yearsOfExperienceIndex: 0,
  });

  const searchEducationalQualification = watch(
    'searchEducationalQualification'
  );

  const filteredEducationalQualifications = useMemo(() => {
    const educationalQualifications = data?.educational_qualifications?.map(
      (educationalQualification) => ({
        value: educationalQualification,
        name: educationalQualification,
      })
    );
    if (!searchEducationalQualification) {
      return educationalQualifications;
    }

    return educationalQualifications?.filter((educationalQualification) =>
      educationalQualification.name
        .toLowerCase()
        .includes(searchEducationalQualification.toLowerCase())
    );
  }, [searchEducationalQualification, data]);

  const searchIndustryType = watch('searchIndustryType');

  const filteredIndustryType = useMemo(() => {
    const industryTypes = data?.industry_type?.map((industryType) => ({
      value: industryType,
      name: industryType,
    }));
    if (!searchIndustryType) {
      return industryTypes;
    }

    return industryTypes?.filter((industryType) =>
      industryType.name.toLowerCase().includes(searchIndustryType.toLowerCase())
    );
  }, [searchIndustryType, data]);

  const handleIncrease = (id) => {
    setValue(id, (getValues(id) || 0) + 1);
    handleChange(id);
  };

  const handleDecrease = (id) => {
    const currentValue = getValues(id) || 0;
    if (currentValue > 0) {
      setValue(id, currentValue - 1);
      handleChange(id);
    }
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && file.type === 'application/pdf') {
      setSelectedFile(file);
      setSelectedFileError(null);
      // Process the file as needed
    } else {
      setSelectedFile(null);
      setSelectedFileError('Please upload a PDF file.');
    }
  };

  const handleClick = () => {
    document.getElementById('fileInput').click();
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (event) => {
    event.preventDefault();
    setIsDragging(false);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setIsDragging(false);
    const file = event.dataTransfer.files[0];
    if (file && file.type === 'application/pdf') {
      setSelectedFile(file);
      setSelectedFileError(null);
    } else {
      setSelectedFile(null);
      setSelectedFileError('Please upload a PDF file.');
    }
  };

  const handleDelete = () => {
    setSelectedFile(null);
    setSelectedFileError(null);
  };

  const debouncedTrigger = useRef(
    debounce((trigger, fieldNames) => {
      fieldNames.forEach((fieldName) => trigger(fieldName));
    }, 500)
  ).current;

  useEffect(() => {
    if (changedFields.length > 0) {
      debouncedTrigger(trigger, changedFields);
      setChangedFields([]);
    }
  }, [changedFields, trigger, debouncedTrigger]);

  const handleChange = (fieldName) => {
    setChangedFields((prev) => [...new Set([...prev, fieldName])]);
  };

  const scrollToForm = () => {
    applyFormRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  if (isEmpty(data)) return null;

  return (
    <div style={{ background: '#f5f9fe' }}>
      <section className={styles.jobsMainContent}>
        <div className={styles.contentWrapper}>
          <div className={styles.columnLarge}>
            <div className={styles.headerContainer}>
              <div className={styles.headerWrapper}>
                <div className={styles.headerTitle}>{data.role}</div>

                <div className={styles.captionWrapper}>
                  <span className={styles.captionText}>
                    {`${data.job_details.total_applicants_count} people applied`}{' '}
                  </span>
                  <span className={styles.dot}></span>
                  <span className={styles.captionText}>
                    {convertUnixTimestampToDaysAgo(data.job_details.posted_on)}
                  </span>
                </div>
              </div>
              <div className={styles.headerCompany}>{data.company}</div>
              <button className={styles.btnGreen} onClick={scrollToForm}>
                apply now
              </button>
            </div>
            <div className={styles.rowWrapper}>
              <div className={styles.rowPadding}>
                <div className={styles.rowHeader}>
                  <span className={styles.headerText}>Job Details</span>
                </div>
                <div className={styles.infoRow}>
                  <div className={styles.infoColumn}>
                    <span className={styles.label}>Status</span>
                    <span
                      className={styles.labelText + ' ' + styles.statusValue}>
                      {data.job_details.status ? 'Active' : 'Inactive'}
                    </span>
                  </div>
                  <div className={styles.infoColumn}>
                    <span className={styles.label}>Team</span>
                    <span className={styles.labelText}>
                      {data.job_details.team}
                    </span>
                  </div>
                  <div className={styles.infoColumn}>
                    <span className={styles.label}>Job Location</span>
                    <span className={styles.labelText}>
                      {data.job_details.job_location}
                    </span>
                  </div>
                  <div className={styles.infoColumn}>
                    <span className={styles.label}>Job Type</span>
                    <span className={styles.labelText}>
                      {data.job_details.job_type}
                    </span>
                  </div>
                  <div className={styles.infoColumn}>
                    <span className={styles.label}>Level</span>
                    <span className={styles.labelText}>
                      {data.job_details.level}
                    </span>
                  </div>
                  <div className={styles.infoColumn}>
                    <span className={styles.label}>No. of Openings</span>
                    <span className={styles.labelText}>
                      {data.job_details.number_of_openings}
                    </span>
                  </div>
                  {/* <div className={styles.infoColumn}>
                    <span className={styles.label}>Posted by</span>
                    <span className={styles.labelText}>
                      {data.job_details.posted_by}
                    </span>
                  </div>
                  <div className={styles.infoColumn}>
                    <span className={styles.label}>Posted on</span>
                    <span className={styles.labelText}>
                      {data.job_details.posted_on}
                    </span>
                  </div> */}
                </div>
              </div>
            </div>

            <div className={styles.rowWrapper}>
              <div className={styles.rowPadding}>
                <div className={styles.rowHeader}>
                  <span className={styles.headerText}>Job Description</span>
                </div>

                {data?.job_description !== 'N/A' && (
                  <div
                    className={styles.infoRowExtra}
                    style={
                      data?.kra_okr !== 'N/A' ? { marginBottom: '2.4rem' } : {}
                    }>
                    <div className={styles.infoColumn}>
                      <span className={styles.label}>Role overview</span>
                      <div className={styles.labelText}>
                        {data?.job_description
                          ?.split('\n')
                          .map((line, index) => (
                            <React.Fragment key={index}>
                              {line.replace(/\r/g, '')} <br />
                            </React.Fragment>
                          ))}
                      </div>
                    </div>
                  </div>
                )}
                {data?.kra_okr !== 'N/A' && (
                  <div className={styles.infoRowExtra}>
                    <div className={styles.infoColumn}>
                      <span className={styles.label}>
                        Qualifications and Skillset
                      </span>
                      <div className={styles.labelText}>
                        {data?.kra_okr?.split('\n').map((line, index) => (
                          <React.Fragment key={index}>
                            {line.replace(/\r/g, '')} <br />
                          </React.Fragment>
                        ))}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div
              className={styles.rowWrapper}
              id={'applyForm'}
              ref={applyFormRef}>
              <div className={styles.rowPadding}>
                <div className={styles.rowHeader}>
                  <span className={styles.headerText}>Apply for this role</span>
                </div>
                <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
                  <label htmlFor='applicantName' className={styles.labelTitle}>
                    Applicant Name
                    <span className={styles.requiredSymbol}>*</span>
                  </label>
                  <div
                    style={{ position: 'relative' }}
                    className={
                      styles.marginBottom +
                      ' ' +
                      (errors.applicantName?.message ? styles.errorOutline : '')
                    }>
                    <input
                      className={styles.inputFieldWrap}
                      id='applicantName'
                      {...register('applicantName', {
                        onChange: () => handleChange('applicantName'),
                      })}
                      placeholder='Enter Applicant Name'
                    />
                    {errors.applicantName?.message && (
                      <div className={styles.errorText}>
                        {errors.applicantName && (
                          <>
                            <img src={iconInfoDanger} alt={'error'} />
                            <span>{errors.applicantName.message}</span>
                          </>
                        )}
                      </div>
                    )}
                  </div>

                  <label htmlFor='currentRole' className={styles.labelTitle}>
                    Current Role
                    <span className={styles.requiredSymbol}>*</span>
                  </label>
                  <div
                    style={{ position: 'relative' }}
                    className={
                      styles.marginBottom +
                      ' ' +
                      (errors.currentRole?.message ? styles.errorOutline : '')
                    }>
                    <input
                      className={styles.inputFieldWrap}
                      id='currentRole'
                      {...register('currentRole', {
                        onChange: () => handleChange('currentRole'),
                      })}
                      placeholder='Enter Current Role'
                    />
                    {errors.currentRole?.message && (
                      <div className={styles.errorText}>
                        {errors.currentRole && (
                          <>
                            <img src={iconInfoDanger} alt={'error'} />
                            <span>{errors.currentRole.message}</span>
                          </>
                        )}
                      </div>
                    )}
                  </div>

                  <label htmlFor='phoneNumber' className={styles.labelTitle}>
                    Phone Number
                    <span className={styles.requiredSymbol}>*</span>
                  </label>
                  <div
                    style={{ position: 'relative' }}
                    className={
                      styles.marginBottom +
                      ' ' +
                      (errors.phoneNumber?.message ? styles.errorOutline : '')
                    }>
                    <input
                      className={styles.inputFieldWrap}
                      id='phoneNumber'
                      {...register('phoneNumber', {
                        onChange: () => {
                          handleChange('phoneNumber');
                        },
                      })}
                      placeholder='Enter Phone Number'
                    />
                    {errors.phoneNumber?.message && (
                      <div className={styles.errorText}>
                        {errors.phoneNumber && (
                          <>
                            <img src={iconInfoDanger} alt={'error'} />
                            <span>{errors.phoneNumber.message}</span>
                          </>
                        )}
                      </div>
                    )}
                  </div>

                  <label htmlFor='emailAddress' className={styles.labelTitle}>
                    Email Address
                    <span className={styles.requiredSymbol}>*</span>
                  </label>
                  <div
                    style={{ position: 'relative' }}
                    className={
                      styles.marginBottom +
                      ' ' +
                      (errors.emailAddress?.message ? styles.errorOutline : '')
                    }>
                    <input
                      className={styles.inputFieldWrap}
                      id='emailAddress'
                      {...register('emailAddress', {
                        onChange: () => {
                          console.log('emailAddress triggered');
                          handleChange('emailAddress');
                        },
                      })}
                      placeholder='Enter Email Address'
                    />
                    {errors.emailAddress?.message && (
                      <div className={styles.errorText}>
                        {errors.emailAddress && (
                          <>
                            <img src={iconInfoDanger} alt={'error'} />
                            <span>{errors.emailAddress.message}</span>
                          </>
                        )}
                      </div>
                    )}
                  </div>

                  <label
                    htmlFor='mostRecentCompany'
                    className={styles.labelTitle}>
                    Most Recent Company
                  </label>
                  <div
                    style={{ position: 'relative' }}
                    className={styles.marginBottom}>
                    <input
                      className={styles.inputFieldWrap}
                      id='mostRecentCompany'
                      {...register('mostRecentCompany', {
                        onChange: () => handleChange('mostRecentCompany'),
                      })}
                      placeholder='Enter Most Recent Company'
                    />
                    {errors.mostRecentCompany?.message && (
                      <div className={styles.errorText}>
                        {errors.mostRecentCompany && (
                          <>
                            <img src={iconInfoDanger} alt={'error'} />
                            <span>{errors.mostRecentCompany.message}</span>
                          </>
                        )}
                      </div>
                    )}
                  </div>

                  <div className={styles.dropDownWrap}>
                    <label
                      htmlFor='educationalQualification'
                      className={styles.labelTitle}>
                      Educational Qualification
                    </label>
                    <div
                      style={{ position: 'relative' }}
                      className={
                        styles.marginBottom +
                        ' ' +
                        (errors.educationalQualification?.message
                          ? styles.errorOutline
                          : '')
                      }>
                      <input
                        className={
                          styles.inputFieldWrap + ' ' + styles.dropDownInputWrap
                        }
                        id='educationalQualification'
                        {...register('educationalQualification', {
                          onChange: () =>
                            handleChange('educationalQualification'),
                        })}
                        placeholder='Select Educational Qualification'
                        readOnly
                        onClick={() => {
                          setSelectedIndices((prevState) => ({
                            ...prevState,
                            educationalQualificationIndex: 0,
                          }));
                          setCurrentDropdownStates((prevState) =>
                            prevState === 'educationalQualification'
                              ? ''
                              : 'educationalQualification'
                          );
                        }}
                      />
                      <img
                        src={iconPointDown}
                        alt='pointDown'
                        style={{
                          position: 'absolute',
                          right: '1.6rem',
                          top: '50%',
                          transform: 'translateY(-50%)',
                        }}
                      />
                    </div>
                    {currentDropdownStates === 'educationalQualification' && (
                      <div
                        className={styles.teamDropdown}
                        ref={dropdownRefs['educationalQualification']['ref']}>
                        <div className={styles.searchWrapper}>
                          <div
                            className={styles.innerInputSearch}
                            style={{ position: 'relative' }}>
                            {!isTyping && (
                              <img
                                style={{
                                  position: 'absolute',
                                  left: '1.2rem',
                                  top: '50%',
                                  transform: 'translateY(-50%)',
                                  marginRight: '1.2rem',
                                  cursor: 'pointer',
                                }}
                                src={iconSearch}
                                alt='iconSearch'
                              />
                            )}
                            <input
                              id='searchEducationalQualification'
                              {...register('searchEducationalQualification')}
                              placeholder='Search Educational Qualification'
                              className={styles.search}
                              onChange={(e) => {
                                setIsTyping(e.target.value !== '');
                                setSelectedIndices((prevState) => ({
                                  ...prevState,
                                  educationalQualificationIndex: 0,
                                }));
                                setValue(
                                  'searchEducationalQualification',
                                  e.target.value
                                );
                                handleChange('educationalQualification');
                              }}
                              onKeyDown={(event) => {
                                if (event.key === 'Enter') {
                                  event.preventDefault();
                                  setCurrentDropdownStates('');
                                  setValue(
                                    'searchEducationalQualification',
                                    ''
                                  );
                                  setValue(
                                    'educationalQualification',
                                    get(
                                      filteredEducationalQualifications,
                                      `[${selectedIndices['educationalQualificationIndex']}].value`,
                                      ''
                                    )
                                  );
                                  handleChange('educationalQualification');
                                } else if (event.key === 'ArrowDown') {
                                  event.preventDefault();
                                  setSelectedIndices((prevState) => {
                                    const prevIndex =
                                      prevState[
                                        'educationalQualificationIndex'
                                      ];
                                    return {
                                      ...prevState,
                                      educationalQualificationIndex: Math.min(
                                        prevIndex + 1,
                                        filteredEducationalQualifications.length -
                                          1
                                      ),
                                    };
                                  });
                                } else if (event.key === 'ArrowUp') {
                                  event.preventDefault();
                                  setSelectedIndices((prevState) => {
                                    const prevIndex =
                                      prevState[
                                        'educationalQualificationIndex'
                                      ];
                                    return {
                                      ...prevState,
                                      educationalQualificationIndex: Math.max(
                                        prevIndex - 1,
                                        0
                                      ),
                                    };
                                  });
                                }
                              }}
                              autoComplete='off'
                            />
                          </div>
                        </div>

                        <div className={styles.searchListWrapper}>
                          {filteredEducationalQualifications
                            ?.slice(0, 5)
                            .map((educationalQualification, index) => (
                              <span
                                key={educationalQualification.value}
                                className={`${styles.teamDropdownItem} ${
                                  selectedIndices[
                                    'educationalQualificationIndex'
                                  ] === index
                                    ? styles.highlightedDropdownItem
                                    : ''
                                } ${
                                  educationalQualification.value ===
                                  getValues('educationalQualification')
                                    ? styles.selectedBackground
                                    : ''
                                }`}
                                onClick={() => {
                                  setValue(
                                    'educationalQualification',
                                    educationalQualification.value
                                  );
                                  setCurrentDropdownStates('');
                                  handleChange('educationalQualification');
                                }}>
                                {educationalQualification.name}
                              </span>
                            ))}
                        </div>
                      </div>
                    )}

                    {errors.educationalQualification?.message && (
                      <div className={styles.errorText}>
                        {errors.educationalQualification && (
                          <>
                            <img src={iconInfoDanger} alt={'error'} />
                            <span>
                              {errors.educationalQualification.message}
                            </span>
                          </>
                        )}
                      </div>
                    )}
                  </div>

                  <label htmlFor='college' className={styles.labelTitle}>
                    College
                    <span className={styles.requiredSymbol}>*</span>
                  </label>
                  <div
                    style={{ position: 'relative' }}
                    className={
                      styles.marginBottom +
                      ' ' +
                      (errors.college?.message ? styles.errorOutline : '')
                    }>
                    <input
                      className={styles.inputFieldWrap}
                      id='college'
                      {...register('college', {
                        onChange: () => handleChange('college'),
                      })}
                      placeholder='Enter College'
                    />
                    {errors.college?.message && (
                      <div className={styles.errorText}>
                        {errors.college && (
                          <>
                            <img src={iconInfoDanger} alt={'error'} />
                            <span>{errors.college.message}</span>
                          </>
                        )}
                      </div>
                    )}
                  </div>

                  <div className={styles.dropDownWrap}>
                    <label htmlFor='industryType' className={styles.labelTitle}>
                      Industry Type
                      <span className={styles.requiredSymbol}>*</span>
                    </label>
                    <div
                      style={{ position: 'relative' }}
                      className={
                        styles.marginBottom +
                        ' ' +
                        (errors.industryType?.message
                          ? styles.errorOutline
                          : '')
                      }>
                      <input
                        className={
                          styles.inputFieldWrap + ' ' + styles.dropDownInputWrap
                        }
                        id='industryType'
                        {...register('industryType', {
                          onChange: () => handleChange('industryType'),
                        })}
                        placeholder='Select Industry Type'
                        readOnly
                        onClick={() => {
                          setSelectedIndices((prevState) => ({
                            ...prevState,
                            industryTypeIndex: 0,
                          }));
                          setCurrentDropdownStates((prevState) =>
                            prevState === 'industryType' ? '' : 'industryType'
                          );
                        }}
                      />
                      <img
                        src={iconPointDown}
                        alt='pointDown'
                        style={{
                          position: 'absolute',
                          right: '1.6rem',
                          top: errors.industryType?.message
                            ? 'calc(50% - 1rem)'
                            : '50%',
                          transform: 'translateY(-50%)',
                        }}
                      />
                      {errors.industryType?.message && (
                        <div className={styles.errorText}>
                          {errors.industryType && (
                            <>
                              <img src={iconInfoDanger} alt={'error'} />
                              <span>{errors.industryType.message}</span>
                            </>
                          )}
                        </div>
                      )}
                    </div>
                    {currentDropdownStates === 'industryType' && (
                      <div
                        className={styles.teamDropdown}
                        ref={dropdownRefs['industryType']['ref']}>
                        <div className={styles.searchWrapper}>
                          <div
                            className={styles.innerInputSearch}
                            style={{ position: 'relative' }}>
                            {!isTyping && (
                              <img
                                style={{
                                  position: 'absolute',
                                  left: '1.2rem',
                                  top: '50%',
                                  transform: 'translateY(-50%)',
                                  marginRight: '1.2rem',
                                  cursor: 'pointer',
                                }}
                                src={iconSearch}
                                alt='iconSearch'
                              />
                            )}
                            <input
                              id='searchIndustryType'
                              {...register('searchIndustryType')}
                              placeholder='search Industry Type'
                              className={styles.search}
                              onChange={(e) => {
                                setIsTyping(e.target.value !== '');
                                setSelectedIndices((prevState) => ({
                                  ...prevState,
                                  industryTypeIndex: 0,
                                }));
                                setValue('searchIndustryType', e.target.value);
                                handleChange('industryType');
                              }}
                              onKeyDown={(event) => {
                                if (event.key === 'Enter') {
                                  event.preventDefault();
                                  setCurrentDropdownStates('');
                                  setValue('searchIndustryType', '');
                                  setValue(
                                    'industryType',
                                    get(
                                      filteredIndustryType,
                                      `[${selectedIndices['industryTypeIndex']}].value`,
                                      ''
                                    )
                                  );
                                  handleChange('industryType');
                                } else if (event.key === 'ArrowDown') {
                                  event.preventDefault();
                                  setSelectedIndices((prevState) => {
                                    const prevIndex =
                                      prevState['industryTypeIndex'];
                                    return {
                                      ...prevState,
                                      industryTypeIndex: Math.min(
                                        prevIndex + 1,
                                        filteredIndustryType.length - 1
                                      ),
                                    };
                                  });
                                } else if (event.key === 'ArrowUp') {
                                  event.preventDefault();
                                  setSelectedIndices((prevState) => {
                                    const prevIndex =
                                      prevState['industryTypeIndex'];
                                    return {
                                      ...prevState,
                                      industryTypeIndex: Math.max(
                                        prevIndex - 1,
                                        0
                                      ),
                                    };
                                  });
                                }
                              }}
                              autoComplete='off'
                            />
                          </div>
                        </div>

                        <div className={styles.searchListWrapper}>
                          {filteredIndustryType
                            ?.slice(0, 5)
                            .map((industryType, index) => (
                              <span
                                key={industryType.value}
                                className={`${styles.teamDropdownItem} ${
                                  selectedIndices['industryTypeIndex'] === index
                                    ? styles.highlightedDropdownItem
                                    : ''
                                } ${
                                  industryType.value ===
                                  getValues('industryType')
                                    ? styles.selectedBackground
                                    : ''
                                }`}
                                onClick={() => {
                                  setValue('industryType', industryType.value);
                                  setCurrentDropdownStates('');
                                  handleChange('industryType');
                                }}>
                                {industryType.name}
                              </span>
                            ))}
                        </div>
                      </div>
                    )}
                  </div>

                  <label
                    htmlFor='noticePEriodMax'
                    className={styles.labelTitle}>
                    Max. Notice Period (in days)
                  </label>
                  <div
                    style={{ position: 'relative' }}
                    className={
                      styles.marginBottom +
                      ' ' +
                      (errors.noticePEriodMax?.message
                        ? styles.errorOutline
                        : '')
                    }>
                    <input
                      className={styles.inputFieldWrap}
                      id='noticePEriodMax'
                      {...register('noticePEriodMax', {
                        onChange: () => handleChange('noticePEriodMax'),
                      })}
                      placeholder='Enter Max. Notice Period'
                      onClick={() => {
                        setSelectedIndices((prevState) => ({
                          ...prevState,
                          noticePEriodMaxIndex: 0,
                        }));
                        setCurrentDropdownStates((prevState) =>
                          prevState === 'noticePEriodMax'
                            ? ''
                            : 'noticePEriodMax'
                        );
                      }}
                    />
                    <span
                      style={{
                        position: 'absolute',
                        right: '1.6rem',
                        top: errors.noticePEriodMax?.message
                          ? 'calc(50% - 1rem)'
                          : '50%',
                        transform: 'translateY(-50%)',
                      }}>
                      <img
                        style={{
                          marginRight: '1.2rem',
                          cursor: 'pointer',
                        }}
                        src={iconMinus}
                        alt='pointDown'
                        onClick={() => handleDecrease('noticePEriodMax')}
                      />

                      <img
                        style={{
                          cursor: 'pointer',
                        }}
                        src={iconPlus}
                        alt='pointDown'
                        onClick={() => handleIncrease('noticePEriodMax')}
                      />
                    </span>
                    {errors.noticePEriodMax?.message && (
                      <div className={styles.errorText}>
                        {errors.noticePEriodMax && (
                          <>
                            <img src={iconInfoDanger} alt={'error'} />
                            <span>{errors.noticePEriodMax.message}</span>
                          </>
                        )}
                      </div>
                    )}
                  </div>

                  <label
                    htmlFor='yearsOfExperience'
                    className={styles.labelTitle}>
                    Years of Experience
                    <span className={styles.requiredSymbol}>*</span>
                  </label>
                  <div
                    style={{ position: 'relative' }}
                    className={
                      styles.marginBottom +
                      ' ' +
                      (errors.yearsOfExperience?.message
                        ? styles.errorOutline
                        : '')
                    }>
                    <input
                      className={styles.inputFieldWrap}
                      id='yearsOfExperience'
                      {...register('yearsOfExperience', {
                        onChange: () => handleChange('yearsOfExperience'),
                      })}
                      placeholder='Enter Years of Experience'
                      onClick={() => {
                        setSelectedIndices((prevState) => ({
                          ...prevState,
                          yearsOfExperienceIndex: 0,
                        }));
                        setCurrentDropdownStates((prevState) =>
                          prevState === 'yearsOfExperience'
                            ? ''
                            : 'yearsOfExperience'
                        );
                      }}
                    />
                    <span
                      style={{
                        position: 'absolute',
                        right: '1.6rem',
                        top: errors.yearsOfExperience?.message
                          ? 'calc(50% - 1rem)'
                          : '50%',
                        transform: 'translateY(-50%)',
                      }}>
                      <img
                        style={{
                          marginRight: '1.2rem',
                          cursor: 'pointer',
                        }}
                        src={iconMinus}
                        alt='pointDown'
                        onClick={() => handleDecrease('yearsOfExperience')}
                      />

                      <img
                        style={{
                          cursor: 'pointer',
                        }}
                        src={iconPlus}
                        alt='pointDown'
                        onClick={() => handleIncrease('yearsOfExperience')}
                      />
                    </span>
                    {errors.yearsOfExperience?.message && (
                      <div className={styles.errorText}>
                        {errors.yearsOfExperience && (
                          <>
                            <img src={iconInfoDanger} alt={'error'} />
                            <span>{errors.yearsOfExperience.message}</span>
                          </>
                        )}
                      </div>
                    )}
                  </div>

                  <label htmlFor='uploadResume' className={styles.labelTitle}>
                    Upload Resume
                    <span className={styles.requiredSymbol}>*</span>
                  </label>
                  {selectedFile ? (
                    <div className={styles.marginBottom}>
                      <div className={styles.fileDisplay}>
                        <div style={{ height: '17.6rem' }}>
                          <canvas ref={canvasRef} />
                        </div>
                        <span className={styles.textWrap}>
                          {selectedFile.name}
                        </span>
                        <img
                          src={iconDelete}
                          alt='Delete'
                          className={styles.deleteIcon}
                          onClick={handleDelete}
                        />
                      </div>

                      <span className={styles.captionWrapper}>
                        <span>Supported format: PDF</span>
                        <span>Maximum size: 25 MB</span>
                      </span>
                    </div>
                  ) : (
                    <div
                      className={
                        styles.uploadWrapper + ' ' + styles.marginBottom
                      }>
                      <div
                        onClick={handleClick}
                        onDragOver={handleDragOver}
                        onDragLeave={handleDragLeave}
                        onDrop={handleDrop}
                        style={{
                          position: 'relative',
                          width: '100%',
                          height: '100%',
                          backgroundImage: `url('data:image/svg+xml,<svg viewBox="0 0 516 176" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="0.5" y="0.5" width="515" height="175" rx="7.5" fill="%23F5F9FE" stroke="${
                            selectedFileError ? '%23ff5757' : '%23AAADCD'
                          }" stroke-dasharray="7 7"/></svg>')`,
                          backgroundSize: 'contain',
                          backgroundRepeat: 'no-repeat',
                          backgroundPosition: 'center',
                          opacity: isDragging ? 0.5 : 1,
                          transition: 'opacity 0.3s ease',
                        }}
                        className={styles.marginBottom}>
                        <input
                          type='file'
                          id='fileInput'
                          style={{ display: 'none' }}
                          accept='application/pdf'
                          onChange={handleFileChange}
                        />
                        <div className={styles.iconWrapper}>
                          <img
                            className={styles.uploadIcon}
                            src={iconUploadBack}
                            alt={'upload'}
                          />
                          <span className={styles.uploadDarkText}>
                            Upload Resume
                          </span>
                          <div>
                            <span className={styles.uploadText}>
                              {`Drag and drop file here or `}
                            </span>
                            <span className={styles.uploadGreenText}>
                              Choose file
                            </span>
                          </div>
                        </div>
                        {selectedFileError ? (
                          // <span>{selectedFileError}</span>
                          <div className={styles.errorText}>
                            {selectedFileError && (
                              <>
                                <img src={iconInfoDanger} alt={'error'} />
                                <span>{selectedFileError}</span>
                              </>
                            )}
                          </div>
                        ) : (
                          <span className={styles.captionWrapper}>
                            <span>Supported format: PDF</span>
                            <span>Maximum size: 25 MB</span>
                          </span>
                        )}
                      </div>
                    </div>
                  )}

                  <label
                    htmlFor='linkedIn'
                    style={selectedFile ? {} : { marginTop: '2.2rem' }}
                    className={styles.labelTitle}>
                    Add Linkedin Profile URL
                  </label>
                  <div
                    style={{ position: 'relative' }}
                    className={
                      styles.marginBottom +
                      ' ' +
                      (errors.linkedIn?.message ? styles.errorOutline : '')
                    }>
                    <input
                      className={styles.inputFieldWrap}
                      id='linkedIn'
                      {...register('linkedIn', {
                        onChange: () => handleChange('linkedIn'),
                      })}
                      placeholder='Enter Add Linkedin Profile URL'
                    />
                    {errors.linkedIn?.message && (
                      <div className={styles.errorText}>
                        {errors.linkedIn && (
                          <>
                            <img src={iconInfoDanger} alt={'error'} />
                            <span>{errors.linkedIn.message}</span>
                          </>
                        )}
                      </div>
                    )}
                  </div>
                  <button
                    type={'submit'}
                    className={`${styles.btnGreen} ${
                      !isValid || !selectedFile ? styles.disabledBtn : ''
                    }`}
                    disabled={!isValid || !selectedFile}>
                    Submit Application
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <SubmitModal />
      <Loader isLoading={showLoader} />
    </div>
  );
};

export default ApplyJob;
