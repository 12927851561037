import { useState, useEffect, useRef } from 'react';
import ModalSkeleton from '../ModalSkeleton/ModalSkeleton';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import useMultipleClickOutside from '../../hooks/useClickOutside';
import DropdownComponent from '../../components/Dropdown/Dropdown';
import {
    iconCross,
    iconSpring,
    iconTrue,
    iconArrowDark,
    iconPointDown,
    iconUploadBack,
    iconDownload,
    iconEdit,
} from '../../assets';
import styles from './BulkUpload.module.css';

import { useSelector } from 'react-redux';

const priorityObj = [
    {
        value: 'trueHire',
        name: 'True Hire',
    },
    {
        value: 'springRecruit',
        name: 'Spring Recruit',
    },
];

const BulkUpload = () => {
    const { isOpen } = useSelector((state) => state.bulkUpload);
    const [currentScreen, setCurrentScreen] = useState('screen1');
    const [currentDropdownStates, setCurrentDropdownStates] = useState('');
    const [currentCompany, setCurrentCompany] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedFileError, setSelectedFileError] = useState(null);
    const [isDragging, setIsDragging] = useState(false);
    const [progress, setProgress] = useState(0);

    const canvasRef = useRef(null);

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        getValues,
        watch,
        reset,
    } = useForm({ defaultValues: { company: '' } });

    const dropdownRefs = {
        priorityMinExp: {
            ref: useRef(),
            id: '#searchPriorityMinExp',
            ignoreId: 'priorityMinExp',
        },
    };

    useMultipleClickOutside(
        Object.keys(dropdownRefs).map((key) => ({
            ref: dropdownRefs[key]['ref'],
            callback: () => setCurrentDropdownStates(''),
            ignoreId: key,
        }))
    );

    useEffect(() => {
        const dropdown = dropdownRefs[currentDropdownStates];
        if (dropdown && dropdown.ref.current) {
            const inputField = dropdown.ref.current.querySelector(dropdown.id);
            if (inputField) {
                inputField.focus();
            }
        }
    }, [currentDropdownStates]);

    const [selectedIndices, setSelectedIndices] = useState({
        priorityMinExpIndex: 0,
    });

    const handleCompanyClick = (company) => {
        setCurrentCompany(company);
        setCurrentScreen('screen2');
        setValue('company', company);
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (!file) return;
        if (file && file.type === 'application/pdf') {
            setSelectedFile(file);
            setSelectedFileError(null);
        } else {
            setSelectedFile(null);
            setSelectedFileError('Please upload a PDF file.');
        }
    };

    const handleClick = () => {
        document.getElementById('fileInput').click();
    };

    const handleDragOver = (event) => {
        event.preventDefault();
        setIsDragging(true);
    };

    const handleDragLeave = (event) => {
        event.preventDefault();
        setIsDragging(false);
    };

    const handleDrop = (event) => {
        event.preventDefault();
        setIsDragging(false);
        const file = event.dataTransfer.files[0];
        if (!file) return;
        if (file && file.type === 'application/pdf') {
            setSelectedFile(file);
            setSelectedFileError(null);
        } else {
            setSelectedFile(null);
            setSelectedFileError('Please upload a PDF file.');
        }
    };

    const handleDelete = () => {
        setSelectedFile(null);
        setSelectedFileError(null);
    };

    const handleSubmitForm = () => {
        const file = selectedFile[0];
        console.log('selectedFile: ', selectedFile);
        console.log('file: ', file);
        const formData = new FormData();
        formData.append('file', file);

        axios
            .post('/bulk-upload', formData, {
                onUploadProgress: (progressEvent) => {
                    const percentCompleted = Math.round(
                        (progressEvent.loaded * 100) / progressEvent.total
                    );
                    setProgress(percentCompleted);
                },
            })
            .then((response) => {
                console.log('File uploaded successfully:', response.data);
            })
            .catch((error) => {
                console.error('Error uploading file:', error);
            });
    };

    if (!isOpen) return null;

    return (
        <ModalSkeleton>
            <div className={styles.wrapper}>
                <div className={styles.modalContent}>
                    <div className={styles.headerWrapper}>
                        <span className={styles.headerText}>Bulk Upload Applicants</span>
                        <img className={styles.closeIcon} src={iconCross} alt='cross' />
                    </div>
                    {currentScreen === 'screen1' && (
                        <>
                            <span className={styles.captionText}>{getValues('company')}</span>
                            <div className={styles.company}>
                                <div
                                    className={styles.companyWrapper}
                                    onClick={() => handleCompanyClick('True Hire')}>
                                    <img src={iconTrue} alt={'truehire'} />
                                    <span className={styles.companyText}>TrueHire</span>
                                </div>
                                <div
                                    className={styles.companyWrapper}
                                    onClick={() => handleCompanyClick('Spring Recruit')}>
                                    <img src={iconSpring} alt={'spring'} />
                                    <span className={styles.companyText}>Spring Recruit</span>
                                </div>
                            </div>
                        </>
                    )}

                    {currentScreen === 'screen2' && (
                        <>
                            <div className={styles.captionRowWrapper}>
                                <div className={styles.captionTextWrapper}>
                                    <img src={iconArrowDark} alt={'go back'} />
                                    <span className={styles.captionTextUpload}>Upload file</span>
                                </div>
                                <div className={styles.dropwdownWrapper}>
                                    <span className={styles.dropwdownLabel}>template:</span>
                                    <div style={{ position: 'relative', width: '21.2rem' }}>
                                        <input
                                            className={styles.inputFieldWrap}
                                            id='priorityMinExp'
                                            {...register('priorityMinExp')}
                                            placeholder='Select'
                                            readOnly
                                            defaultValue={getValues('company')}
                                            onClick={() => {
                                                setSelectedIndices((prevState) => ({
                                                    ...prevState,
                                                    priorityMinExpIndex: 0,
                                                }));
                                                setCurrentDropdownStates((prevState) =>
                                                    prevState === 'priorityMinExp' ? '' : 'priorityMinExp'
                                                );
                                            }}
                                            autoComplete='off'
                                        />
                                        <img
                                            src={iconPointDown}
                                            alt='pointDown'
                                            style={{
                                                position: 'absolute',
                                                right: '1.6rem',
                                                top: '50%',
                                                transform: 'translateY(-50%)',
                                            }}
                                        />
                                        <DropdownComponent
                                            currentDropdownState={currentDropdownStates}
                                            dropdownRef={dropdownRefs['priorityMinExp']['ref']}
                                            items={priorityObj}
                                            selectedIndices={selectedIndices}
                                            setValue={setValue}
                                            setCurrentDropdownState={setCurrentDropdownStates}
                                            dropdownStateName='priorityMinExp'
                                            dropdownId='searchPriorityMinExp'
                                            indexName='priorityMinExpIndex'
                                            setSelectedIndices={setSelectedIndices}
                                            getValues={getValues}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div>
                                <div
                                    className={styles.uploadWrapper + ' ' + styles.marginBottom}>
                                    <div
                                        onDragOver={handleDragOver}
                                        onDragLeave={handleDragLeave}
                                        onDrop={handleDrop}
                                        style={{
                                            position: 'relative',
                                            width: '100%',
                                            height: '100%',
                                            backgroundImage: `url('data:image/svg+xml,<svg viewBox="0 0 672 260" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="0.5" y="0.5" width="671" height="259" rx="7.5" fill="%23F5F9FE" stroke="${selectedFileError ? '%23ff5757' : '%23049F7A'
                                                }" stroke-dasharray="7 7"/></svg>')`,
                                            backgroundSize: 'contain',
                                            backgroundRepeat: 'no-repeat',
                                            backgroundPosition: 'center',
                                            opacity: isDragging ? 0.5 : 1,
                                            transition: 'opacity 0.3s ease',
                                        }}
                                        className={styles.marginBottom}>
                                        <input
                                            type='file'
                                            id='fileInput'
                                            style={{ display: 'none' }}
                                            accept='application/pdf'
                                            onChange={handleFileChange}
                                        />
                                        {selectedFile ? (
                                            <div className={styles.iconWrapper}>
                                                <div className={styles.fileUploadedWrapper}>
                                                    <span className={styles.inputInfoText}>
                                                        File added!
                                                    </span>

                                                    <div className={styles.inputUploadedWrapper}>
                                                        <input
                                                            className={styles.inputUploaded}
                                                            value={selectedFile.name}
                                                            readOnly={true}
                                                        />
                                                        <span
                                                            className={styles.changeUploaded}
                                                            onClick={handleClick}>
                                                            <img src={iconEdit} alt={'edit file'} />
                                                            change
                                                        </span>
                                                    </div>

                                                    <span className={styles.inputCaptionText}>
                                                        File size: 25 MB
                                                    </span>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className={styles.iconWrapper}>
                                                <img
                                                    className={styles.uploadIcon}
                                                    src={iconUploadBack}
                                                    alt={'upload'}
                                                />
                                                <div style={{ marginBottom: '2rem' }}>
                                                    <span className={styles.uploadText}>
                                                        {`Drag and drop file here or `}
                                                    </span>
                                                    <span
                                                        className={styles.uploadGreenText}
                                                        onClick={handleClick}>
                                                        Choose file
                                                    </span>
                                                </div>
                                                <div className={styles.descCaptionWrapper}>
                                                    <span className={styles.descCaptionText}>
                                                        Supported formats:{' '}
                                                        <span className={styles.descCaptionTextBold}>
                                                            XLS, XLSX
                                                        </span>
                                                    </span>
                                                    <span className={styles.descCaptionDot}></span>
                                                    <span className={styles.descCaptionText}>
                                                        Max. size:{' '}
                                                        <span className={styles.descCaptionTextBold}>
                                                            25 MB
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>
                                        )}

                                        {/* {selectedFileError ? (
                          <div className={styles.errorText}>
                            {selectedFileError && (
                              <>
                                <img src={iconInfoDanger} alt={'error'} />
                                <span>{selectedFileError}</span>
                              </>
                            )}
                          </div>
                        ) : (
                          <span className={styles.captionWrapper}>
                            <span>Supported format: PDF</span>
                            <span>Maximum size: 25 MB</span>
                          </span>
                        )} */}
                                    </div>
                                </div>
                            </div>

                            <div className={styles.templateWrapper}>
                                <div className={styles.templateDescWrapper}>
                                    <span className={styles.templateHeader}>
                                        Download{' '}
                                        <span className={styles.templateCompanyText}>
                                            {getValues('company')}
                                        </span>{' '}
                                        Template
                                    </span>
                                    <p className={styles.templateDesc}>
                                        You can download the attached template and use it as a
                                        starting point for your own file
                                    </p>
                                </div>
                                <button className={styles.downloadCta}>
                                    <img src={iconDownload} alt={'download'} />
                                    <span className={styles.buttonText}>Download</span>
                                </button>
                            </div>
                            <div className={styles.formSubmitWrapper}>
                                <button className={styles.cancelCta}>cancel</button>
                                <button
                                    className={styles.submitCta}
                                    onClick={() => setCurrentScreen('screen3')}>
                                    Next
                                </button>
                            </div>
                        </>
                    )}

                    {currentScreen === 'screen3' && (
                        <>
                            <div className={styles.captionRowWrapper}>
                                <div className={styles.captionTextWrapper}>
                                    <img src={iconArrowDark} alt={'go back'} />
                                    <span className={styles.captionTextUpload}>Upload file</span>
                                </div>
                                <div className={styles.dropwdownWrapper}>
                                    <span className={styles.dropwdownLabel}>template:</span>
                                    <div style={{ position: 'relative', width: '21.2rem' }}>
                                        <input
                                            className={styles.inputFieldWrap}
                                            id='priorityMinExp'
                                            {...register('priorityMinExp')}
                                            placeholder='Select'
                                            readOnly
                                            defaultValue={getValues('company')}
                                            onClick={() => {
                                                setSelectedIndices((prevState) => ({
                                                    ...prevState,
                                                    priorityMinExpIndex: 0,
                                                }));
                                                setCurrentDropdownStates((prevState) =>
                                                    prevState === 'priorityMinExp' ? '' : 'priorityMinExp'
                                                );
                                            }}
                                            autoComplete='off'
                                        />
                                        <img
                                            src={iconPointDown}
                                            alt='pointDown'
                                            style={{
                                                position: 'absolute',
                                                right: '1.6rem',
                                                top: '50%',
                                                transform: 'translateY(-50%)',
                                            }}
                                        />
                                        <DropdownComponent
                                            currentDropdownState={currentDropdownStates}
                                            dropdownRef={dropdownRefs['priorityMinExp']['ref']}
                                            items={priorityObj}
                                            selectedIndices={selectedIndices}
                                            setValue={setValue}
                                            setCurrentDropdownState={setCurrentDropdownStates}
                                            dropdownStateName='priorityMinExp'
                                            dropdownId='searchPriorityMinExp'
                                            indexName='priorityMinExpIndex'
                                            setSelectedIndices={setSelectedIndices}
                                            getValues={getValues}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div>
                                <div
                                    className={styles.uploadWrapper + ' ' + styles.marginBottom}>
                                    <div
                                        onDragOver={handleDragOver}
                                        onDragLeave={handleDragLeave}
                                        onDrop={handleDrop}
                                        style={{
                                            position: 'relative',
                                            width: '100%',
                                            height: '100%',
                                            backgroundImage: `url('data:image/svg+xml,<svg viewBox="0 0 672 260" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="0.5" y="0.5" width="671" height="259" rx="7.5" fill="%23F5F9FE" stroke="${selectedFileError ? '%23ff5757' : '%23049F7A'
                                                }" stroke-dasharray="7 7"/></svg>')`,
                                            backgroundSize: 'contain',
                                            backgroundRepeat: 'no-repeat',
                                            backgroundPosition: 'center',
                                            opacity: isDragging ? 0.5 : 1,
                                            transition: 'opacity 0.3s ease',
                                        }}
                                        className={styles.marginBottom}>
                                        <input
                                            type='file'
                                            id='fileInput'
                                            style={{ display: 'none' }}
                                            accept='application/pdf'
                                            onChange={handleFileChange}
                                        />
                                        {selectedFile ? (
                                            <div className={styles.iconWrapper}>
                                                <div className={styles.fileUploadedWrapper}>
                                                    <span className={styles.inputInfoText}>
                                                        File added!
                                                    </span>

                                                    <div className={styles.inputUploadedWrapper}>
                                                        <input
                                                            className={styles.inputUploaded}
                                                            value={selectedFile.name}
                                                            readOnly={true}
                                                        />
                                                        <span
                                                            className={styles.changeUploaded}
                                                            onClick={handleClick}>
                                                            <img src={iconEdit} alt={'edit file'} />
                                                            change
                                                        </span>
                                                    </div>

                                                    <span className={styles.inputCaptionText}>
                                                        File size: 25 MB
                                                    </span>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className={styles.iconWrapper}>
                                                <img
                                                    className={styles.uploadIcon}
                                                    src={iconUploadBack}
                                                    alt={'upload'}
                                                />
                                                <div style={{ marginBottom: '2rem' }}>
                                                    <span className={styles.uploadText}>
                                                        {`Drag and drop file here or `}
                                                    </span>
                                                    <span
                                                        className={styles.uploadGreenText}
                                                        onClick={handleClick}>
                                                        Choose file
                                                    </span>
                                                </div>
                                                <div className={styles.descCaptionWrapper}>
                                                    <span className={styles.descCaptionText}>
                                                        Supported formats:{' '}
                                                        <span className={styles.descCaptionTextBold}>
                                                            ZIP
                                                        </span>
                                                    </span>
                                                    <span className={styles.descCaptionDot}></span>
                                                    <span className={styles.descCaptionText}>
                                                        Max. size:{' '}
                                                        <span className={styles.descCaptionTextBold}>
                                                            25 MB
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>

                            <div className={styles.templateWrapper}>
                                <div className={styles.templateDescWrapper}>
                                    <span className={styles.templateHeader}>
                                        Download{' '}
                                        Zip file
                                    </span>
                                    <p className={styles.templateDesc}>
                                        You can download the sample zip file and use it as a
                                        reference.
                                    </p>
                                </div>
                                <button className={styles.downloadCta}>
                                    <img src={iconDownload} alt={'download'} />
                                    <span className={styles.buttonText}>Download</span>
                                </button>
                            </div>
                            <div className={styles.formSubmitWrapper}>
                                <button className={styles.cancelCta}>cancel</button>
                                <button className={styles.submitCta}>submit</button>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </ModalSkeleton>
    );
};

export default BulkUpload;
