import React, { useEffect, useState, useRef, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  fetchCreateJobData,
  postCreateJobData,
} from '../../redux/createJobSlice';
import { useForm } from 'react-hook-form';
import styles from './CreateJob.module.css';
import useMultipleClickOutside from '../../hooks/useClickOutside';
import {
  iconPointDown,
  iconMap,
  iconPlus,
  iconMinus,
  iconSearch,
} from '../../assets';
import DropdownComponent from '../../components/Dropdown/Dropdown';
import { openModal, closeModal } from '../../redux/submitModalSlice';

import { get } from 'lodash';
import SubmitModal from '../../components/SubmitModal/SubmitModal';
const {
  headerText,
  mainInner,
  columnOne,
  columnTwo,
  column1Row1,
  column1Row2,
  row2Wrap,
  form,
  row2item2,
  row2item1,
  columnTwoInner,
  search,
  columnTwoRow1,
  columnTwoRow2,
  columnTwoTextArea,
  searchListWrapper,
  submitButton,
  btnCancel,
  btnSubmit,
  teamDropdown,
  teamDropdownItem,
  highlightedDropdownItem,
  selectedBackground,
  labelTitle,
  mainTitle,
  inputFieldWrap,
  dropDownWrap,
  dropDownInputWrap,
  marginBottom,
  extraMasala,
  innerInputSearch,
  searchWrapper,
} = styles;
const priorityObj = [
  {
    value: '3',
    name: 'High',
  },
  {
    value: '2',
    name: 'Mid',
  },
  {
    value: '1',
    name: 'Low',
  },
  {
    value: '0',
    name: 'No preference',
  },
];

const jd = [
  {
    value: '2',
    name: 'High',
  },
  {
    value: '1',
    name: 'Low',
  },
  {
    value: '0',
    name: 'No preference',
  },
];

const jobTypes = [
  {
    value: 'fullTime',
    name: 'Full time',
  },
  {
    value: 'partTime',
    name: 'Part time',
  },
  {
    value: 'internship',
    name: 'Internship',
  },
];

const jobLevels = [
  {
    value: 'individualContributor',
    name: 'Individual Contributor',
  },
  {
    value: 'management',
    name: 'Management',
  },
  {
    value: 'seniorManagement',
    name: 'Senior Management',
  },
];

const collegeTiers = [
  {
    value: '1',
    name: 'Tier 1',
  },
  {
    value: '2',
    name: 'Tier 2',
  },
  {
    value: '3',
    name: 'Tier 3',
  },
];

function findAndAssignValue(key, array, obj, useName = false) {
  const foundObj = array.find((item) => item.name === obj[key]);
  return foundObj ? foundObj[useName ? 'name' : 'value'] : obj[key];
}

function transformKeys(dataTotransform) {
  const clientToServerKeyMap = {
    team: 'team',
    domain: 'industry_type',
    role: 'role',
    company: 'company',
    location: 'location',
    jobType: 'job_type',
    level: 'level',
    openings: 'open_positions',
    expMin: 'min_years_experience',
    expMax: 'max_years_experience',
    priorityMinExp: 'years_experience_preference',
    priorityDomain: 'industry_relevance_preference',
    collegeTier: 'college_tier',
    priorityCollegeTier: 'college_tier_preference',
    noticePEriodMax: 'notice_period',
    priorityNoticePeriodMax: 'notice_period_preference',
    priorityJobResp: 'jd_preference',
    job_description: 'job_description',
    priorityKeyRole: 'kra_preference',
    kra_okr: 'kra_okr',
  };

  let newObject = {};
  for (let key in dataTotransform) {
    if (clientToServerKeyMap.hasOwnProperty(key)) {
      if (key === 'jobType') {
        newObject[clientToServerKeyMap[key]] = findAndAssignValue(
          key,
          jobTypes,
          dataTotransform,
          true
        );
      } else if (key === 'level') {
        newObject[clientToServerKeyMap[key]] = findAndAssignValue(
          key,
          jobLevels,
          dataTotransform
        );
      } else if (key === 'collegeTier') {
        newObject[clientToServerKeyMap[key]] = findAndAssignValue(
          key,
          collegeTiers,
          dataTotransform
        );
      } else if (key === 'priorityJobResp' || key === 'priorityKeyRole') {
        newObject[clientToServerKeyMap[key]] = findAndAssignValue(
          key,
          jd,
          dataTotransform
        );
      } else if (key.startsWith('priority')) {
        newObject[clientToServerKeyMap[key]] = findAndAssignValue(
          key,
          priorityObj,
          dataTotransform
        );
      } else {
        newObject[clientToServerKeyMap[key]] = dataTotransform[key];
      }
    }
  }
  return newObject;
}
const CreateJob = () => {
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.createJob);
  const { isOpen } = useSelector((state) => state.submitModal);
  const [isTyping, setIsTyping] = useState(false);
  const [currentDropdownStates, setCurrentDropdownStates] = useState('');
  const navigate = useNavigate();

  const dropdownRefs = {
    domain: { ref: useRef(), id: '#searchdomain', ignoreId: 'domain' },
    team: { ref: useRef(), id: '#searchTeam', ignoreId: 'team' },
    jobType: { ref: useRef(), id: '#searchJobType', ignoreId: 'jobType' },
    level: { ref: useRef(), id: '#searchLevel', ignoreId: 'level' },
    collegeTier: {
      ref: useRef(),
      id: '#searchCollegeTier',
      ignoreId: 'collegeTier',
    },
    priorityMinExp: {
      ref: useRef(),
      id: '#searchPriorityMinExp',
      ignoreId: 'priorityMinExp',
    },
    priorityDomain: {
      ref: useRef(),
      id: '#searchPriorityDomain',
      ignoreId: 'priorityDomain',
    },
    priorityCollegeTier: {
      ref: useRef(),
      id: '#searchPriorityCollegeTier',
      ignoreId: 'priorityCollegeTier',
    },
    priorityNoticePeriodMax: {
      ref: useRef(),
      id: '#searchPriorityNoticePeriodMax',
      ignoreId: 'priorityNoticePeriodMax',
    },
    priorityJobResp: {
      ref: useRef(),
      id: '#searchPriorityJobResp',
      ignoreId: 'priorityJobResp',
    },
    priorityKeyRole: {
      ref: useRef(),
      id: '#searchPriorityKeyRole',
      ignoreId: 'priorityKeyRole',
    },
  };

  useMultipleClickOutside(
    Object.keys(dropdownRefs).map((key) => ({
      ref: dropdownRefs[key]['ref'],
      callback: () => setCurrentDropdownStates(''),
      ignoreId: key,
    }))
  );

  useEffect(() => {
    const dropdown = dropdownRefs[currentDropdownStates];
    if (dropdown && dropdown.ref.current) {
      const inputField = dropdown.ref.current.querySelector(dropdown.id);
      if (inputField) {
        inputField.focus();
      }
    }
  }, [currentDropdownStates]);

  const [selectedIndices, setSelectedIndices] = useState({
    teamIndex: 0,
    domainIndex: 0,
    jobTypeIndex: 0,
    levelIndex: 0,
    collegeTierIndex: 0,
    priorityMinExpIndex: 0,
    priorityDomainIndex: 0,
    priorityCollegeTierIndex: 0,
    priorityNoticePeriodMaxIndex: 0,
    priorityJobRespIndex: 0,
  });

  const handleIncrease = (id) => {
    setValue(id, (getValues(id) || 0) + 1);
  };

  const handleDecrease = (id) => {
    const currentValue = getValues(id) || 0;
    if (currentValue > 0) {
      setValue(id, currentValue - 1);
    }
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    watch,
    reset,
  } = useForm({ defaultValues: { team: '', domain: '' } });

  const onSubmit = async (data) => {
    const newObj = transformKeys(data);
    const resultAction = await dispatch(postCreateJobData(newObj));
    if (resultAction.type === 'createJob/postData/fulfilled') {
      reset();
    }
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        setCurrentDropdownStates('');
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useEffect(() => {
    dispatch(fetchCreateJobData());
  }, [dispatch]);

  const searchTeam = watch('searchTeam');

  const filteredTeams = useMemo(() => {
    const teams = data?.teams?.map((team) => ({ value: team, name: team }));
    if (!searchTeam) {
      return teams;
    }

    return teams?.filter((team) =>
      team.name.toLowerCase().includes(searchTeam.toLowerCase())
    );
  }, [searchTeam, data]);

  const searchdomain = watch('searchdomain');
  const filtereddomains = useMemo(() => {
    const domains = data?.industry_types?.map((team) => ({
      value: team,
      name: team,
    }));
    if (!searchdomain) {
      return domains;
    }

    return domains?.filter((domain) =>
      domain.name.toLowerCase().includes(searchdomain.toLowerCase())
    );
  }, [searchdomain, data]);

  return (
    <section className='mainContent'>
      <h1 className={headerText}>Create a Job</h1>
      <div className={mainInner}>
        <form onSubmit={handleSubmit(onSubmit)} className={form}>
          <div className={columnOne}>
            <div className={column1Row1}>
              <h3 className={mainTitle}>Job Details</h3>
              <label htmlFor='role' className={labelTitle}>
                Job Title
              </label>
              <div style={{ position: 'relative' }} className={marginBottom}>
                <input
                  className={inputFieldWrap}
                  id='role'
                  {...register('role')}
                  placeholder='Enter Job Title'
                />
                {errors.role && <span>This field is required</span>}
              </div>

              <label htmlFor='company' className={labelTitle}>
                Company Name
              </label>
              <div style={{ position: 'relative' }} className={marginBottom}>
                <input
                  className={inputFieldWrap}
                  id='company'
                  {...register('company')}
                  placeholder='Job Description'
                />
                {errors.company && <span>This field is required</span>}
              </div>
              <div className={dropDownWrap}>
                <label htmlFor='team' className={labelTitle}>
                  Team
                </label>
                <div style={{ position: 'relative' }} className={marginBottom}>
                  <input
                    className={inputFieldWrap + ' ' + dropDownInputWrap}
                    id='team'
                    {...register('team')}
                    placeholder='Select Team'
                    readOnly
                    onClick={() => {
                      setSelectedIndices((prevState) => ({
                        ...prevState,
                        teamIndex: 0,
                      }));
                      setCurrentDropdownStates((prevState) =>
                        prevState === 'team' ? '' : 'team'
                      );
                    }}
                  />
                  <img
                    src={iconPointDown}
                    alt='pointDown'
                    style={{
                      position: 'absolute',
                      right: '1.6rem',
                      top: '50%',
                      transform: 'translateY(-50%)',
                    }}
                  />
                </div>
                {currentDropdownStates === 'team' && (
                  <div
                    className={teamDropdown}
                    ref={dropdownRefs['team']['ref']}>
                    <div className={searchWrapper}>
                      <div
                        className={innerInputSearch}
                        style={{ position: 'relative' }}>
                        {!isTyping && (
                          <img
                            style={{
                              position: 'absolute',
                              left: '1.2rem',
                              top: '50%',
                              transform: 'translateY(-50%)',
                              marginRight: '1.2rem',
                              cursor: 'pointer',
                            }}
                            src={iconSearch}
                            alt='iconSearch'
                          />
                        )}
                        <input
                          id='searchTeam'
                          {...register('searchTeam')}
                          placeholder='search team'
                          className={search}
                          onChange={(e) => {
                            setIsTyping(e.target.value !== '');
                            setSelectedIndices((prevState) => ({
                              ...prevState,
                              teamIndex: 0,
                            }));
                            setValue('searchTeam', e.target.value);
                          }}
                          onKeyDown={(event) => {
                            if (event.key === 'Enter') {
                              event.preventDefault();
                              setCurrentDropdownStates('');
                              setValue('searchTeam', '');
                              setValue(
                                'team',
                                get(
                                  filteredTeams,
                                  `[${selectedIndices['teamIndex']}].value`,
                                  ''
                                )
                              );
                            } else if (event.key === 'ArrowDown') {
                              event.preventDefault();
                              setSelectedIndices((prevState) => {
                                const prevIndex = prevState['teamIndex'];
                                return {
                                  ...prevState,
                                  teamIndex: Math.min(
                                    prevIndex + 1,
                                    filteredTeams.length - 1
                                  ),
                                };
                              });
                            } else if (event.key === 'ArrowUp') {
                              event.preventDefault();
                              setSelectedIndices((prevState) => {
                                const prevIndex = prevState['teamIndex'];
                                return {
                                  ...prevState,
                                  teamIndex: Math.max(prevIndex - 1, 0),
                                };
                              });
                            }
                          }}
                          autoComplete='off'
                        />
                      </div>
                    </div>

                    <div className={searchListWrapper}>
                      {filteredTeams?.slice(0, 5).map((team, index) => (
                        <span
                          key={team.value}
                          className={`${teamDropdownItem} ${
                            selectedIndices['teamIndex'] === index
                              ? highlightedDropdownItem
                              : ''
                          } ${
                            team.value === getValues('team')
                              ? selectedBackground
                              : ''
                          }`}
                          onClick={() => {
                            setValue('team', team.value);
                            setCurrentDropdownStates('');
                          }}>
                          {team.name}
                        </span>
                      ))}
                    </div>
                  </div>
                )}

                {errors.company && <span>This field is required</span>}
              </div>

              <label htmlFor='location' className={labelTitle}>
                Job Location
              </label>
              <div style={{ position: 'relative' }} className={marginBottom}>
                <input
                  className={inputFieldWrap}
                  id='location'
                  {...register('location')}
                  placeholder='Enter Location (City, State)'
                />
                <img
                  src={iconMap}
                  alt='pointDown'
                  style={{
                    position: 'absolute',
                    right: '1.6rem',
                    top: '50%',
                    transform: 'translateY(-50%)',
                  }}
                />
              </div>

              {errors.company && <span>This field is required</span>}

              <label htmlFor='jobType' className={labelTitle}>
                Job Type
              </label>
              <div style={{ position: 'relative' }} className={marginBottom}>
                <input
                  className={inputFieldWrap}
                  id='jobType'
                  readOnly
                  {...register('jobType')}
                  placeholder='Select Job Type'
                  onClick={() => {
                    setSelectedIndices((prevState) => ({
                      ...prevState,
                      jobTypeIndex: 0,
                    }));
                    setCurrentDropdownStates((prevState) =>
                      prevState === 'jobType' ? '' : 'jobType'
                    );
                  }}
                  autoComplete='off'
                />
                <img
                  src={iconPointDown}
                  alt='pointDown'
                  style={{
                    position: 'absolute',
                    right: '1.6rem',
                    top: '50%',
                    transform: 'translateY(-50%)',
                  }}
                />
                <DropdownComponent
                  currentDropdownState={currentDropdownStates}
                  dropdownRef={dropdownRefs['jobType']['ref']}
                  items={jobTypes}
                  selectedIndices={selectedIndices}
                  setValue={setValue}
                  setCurrentDropdownState={setCurrentDropdownStates}
                  dropdownStateName='jobType'
                  dropdownId='searchJobType'
                  indexName='jobTypeIndex'
                  setSelectedIndices={setSelectedIndices}
                  getValues={getValues}
                />
              </div>

              {errors.company && <span>This field is required</span>}

              <label htmlFor='level' className={labelTitle}>
                Level
              </label>
              <div style={{ position: 'relative' }} className={marginBottom}>
                <input
                  className={inputFieldWrap}
                  id='level'
                  readOnly
                  {...register('level')}
                  placeholder='Select Job Level'
                  onClick={() => {
                    setSelectedIndices((prevState) => ({
                      ...prevState,
                      levelIndex: 0,
                    }));
                    setCurrentDropdownStates((prevState) =>
                      prevState === 'level' ? '' : 'level'
                    );
                  }}
                  autoComplete='off'
                />
                <img
                  src={iconPointDown}
                  alt='pointDown'
                  style={{
                    position: 'absolute',
                    right: '1.6rem',
                    top: '50%',
                    transform: 'translateY(-50%)',
                  }}
                />
                <DropdownComponent
                  currentDropdownState={currentDropdownStates}
                  dropdownRef={dropdownRefs['level']['ref']}
                  items={jobLevels}
                  selectedIndices={selectedIndices}
                  setValue={setValue}
                  setCurrentDropdownState={setCurrentDropdownStates}
                  dropdownStateName='level'
                  dropdownId='searchLevel'
                  indexName='levelIndex'
                  setSelectedIndices={setSelectedIndices}
                  getValues={getValues}
                />
              </div>

              {errors.company && <span>This field is required</span>}

              <label htmlFor='openings' className={labelTitle}>
                No. of Openings
              </label>
              <div style={{ position: 'relative' }}>
                <input
                  className={inputFieldWrap}
                  id='openings'
                  {...register('openings')}
                  placeholder='Select Job Level'
                />
                <span
                  style={{
                    position: 'absolute',
                    right: '1.6rem',
                    top: '50%',
                    transform: 'translateY(-50%)',
                  }}>
                  <img
                    style={{
                      marginRight: '1.2rem',
                      cursor: 'pointer',
                    }}
                    src={iconMinus}
                    alt='pointDown'
                    onClick={() => handleDecrease('openings')}
                  />

                  <img
                    style={{
                      cursor: 'pointer',
                    }}
                    src={iconPlus}
                    alt='pointUp'
                    onClick={() => handleIncrease('openings')}
                  />
                </span>
              </div>

              {errors.company && <span>This field is required</span>}
            </div>

            <div className={column1Row2}>
              <h3 className={mainTitle}>Job Criteria</h3>
              <div className={row2Wrap + ' ' + marginBottom}>
                <div
                  className={row2item1}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '1rem',
                  }}>
                  <div style={{ flex: '1' }}>
                    <label htmlFor='expMin' className={labelTitle}>
                      Min. Yrs of Experience
                    </label>
                    <div style={{ position: 'relative' }}>
                      <input
                        className={inputFieldWrap}
                        id='expMin'
                        {...register('expMin')}
                        placeholder='Enter Min. Yrs'
                      />
                      <span
                        style={{
                          position: 'absolute',
                          right: '1.6rem',
                          top: '50%',
                          transform: 'translateY(-50%)',
                        }}>
                        <img
                          style={{
                            marginRight: '1.2rem',
                            cursor: 'pointer',
                          }}
                          src={iconMinus}
                          alt='pointDown'
                          onClick={() => handleDecrease('expMin')}
                        />

                        <img
                          style={{
                            cursor: 'pointer',
                          }}
                          src={iconPlus}
                          alt='pointDown'
                          onClick={() => handleIncrease('expMin')}
                        />
                      </span>
                      {errors.role && <span>This field is required</span>}
                    </div>
                  </div>
                  <div style={{ flex: '1' }}>
                    <label htmlFor='expMax' className={labelTitle}>
                      Max. Yrs of Experience
                    </label>
                    <div style={{ position: 'relative' }}>
                      <input
                        className={inputFieldWrap}
                        id='expMax'
                        {...register('expMax')}
                        placeholder='Enter Max. Yrs'
                      />
                      <span
                        style={{
                          position: 'absolute',
                          right: '1.6rem',
                          top: '50%',
                          transform: 'translateY(-50%)',
                        }}>
                        <img
                          style={{
                            marginRight: '1.2rem',
                            cursor: 'pointer',
                          }}
                          src={iconMinus}
                          alt='pointDown'
                          onClick={() => handleDecrease('expMax')}
                        />

                        <img
                          style={{
                            cursor: 'pointer',
                          }}
                          src={iconPlus}
                          alt='pointDown'
                          onClick={() => handleIncrease('expMax')}
                        />
                      </span>
                      {errors.role && <span>This field is required</span>}
                    </div>
                  </div>
                </div>
                <div className={row2item2}>
                  <label htmlFor='priorityMinExp' className={labelTitle}>
                    Priority
                  </label>
                  <div style={{ position: 'relative' }}>
                    <input
                      className={inputFieldWrap}
                      id='priorityMinExp'
                      {...register('priorityMinExp')}
                      placeholder='Select'
                      readOnly
                      onClick={() => {
                        setSelectedIndices((prevState) => ({
                          ...prevState,
                          priorityMinExpIndex: 0,
                        }));
                        setCurrentDropdownStates((prevState) =>
                          prevState === 'priorityMinExp' ? '' : 'priorityMinExp'
                        );
                      }}
                      autoComplete='off'
                    />
                    <img
                      src={iconPointDown}
                      alt='pointDown'
                      style={{
                        position: 'absolute',
                        right: '1.6rem',
                        top: '50%',
                        transform: 'translateY(-50%)',
                      }}
                    />
                    <DropdownComponent
                      currentDropdownState={currentDropdownStates}
                      dropdownRef={dropdownRefs['priorityMinExp']['ref']}
                      items={priorityObj}
                      selectedIndices={selectedIndices}
                      setValue={setValue}
                      setCurrentDropdownState={setCurrentDropdownStates}
                      dropdownStateName='priorityMinExp'
                      dropdownId='searchPriorityMinExp'
                      indexName='priorityMinExpIndex'
                      setSelectedIndices={setSelectedIndices}
                      getValues={getValues}
                    />
                  </div>
                </div>
              </div>

              <div className={row2Wrap + ' ' + marginBottom}>
                <div className={row2item1}>
                  <label htmlFor='domain' className={labelTitle}>
                    Industry Type
                  </label>
                  <div style={{ position: 'relative' }}>
                    <input
                      className={inputFieldWrap + ' ' + dropDownInputWrap}
                      id='domain'
                      {...register('domain')}
                      placeholder='Select domain'
                      readOnly
                      onClick={() => {
                        setSelectedIndices((prevState) => ({
                          ...prevState,
                          domainIndex: 0,
                        }));
                        setCurrentDropdownStates((prevState) =>
                          prevState === 'domain' ? '' : 'domain'
                        );
                      }}
                    />
                    <img
                      src={iconPointDown}
                      alt='pointDown'
                      style={{
                        position: 'absolute',
                        right: '1.6rem',
                        top: '50%',
                        transform: 'translateY(-50%)',
                      }}
                    />
                  </div>

                  {currentDropdownStates === 'domain' && (
                    <div
                      className={teamDropdown}
                      ref={dropdownRefs['domain']['ref']}>
                      <div className={searchWrapper}>
                        <div
                          className={innerInputSearch}
                          style={{ position: 'relative' }}>
                          {!isTyping && (
                            <img
                              style={{
                                position: 'absolute',
                                left: '1.2rem',
                                top: '50%',
                                transform: 'translateY(-50%)',
                                marginRight: '1.2rem',
                                cursor: 'pointer',
                              }}
                              src={iconSearch}
                              alt='iconSearch'
                            />
                          )}
                          <input
                            id='searchdomain'
                            {...register('searchdomain')}
                            placeholder='search domain'
                            className={search}
                            onChange={(e) => {
                              setIsTyping(e.target.value !== '');
                              setSelectedIndices((prevState) => ({
                                ...prevState,
                                domainIndex: 0,
                              }));
                              setValue('searchdomain', e.target.value);
                            }}
                            onKeyDown={(event) => {
                              if (event.key === 'Enter') {
                                event.preventDefault();
                                setCurrentDropdownStates('');
                                setValue('searchdomain', '');
                                setValue(
                                  'domain',
                                  get(
                                    filtereddomains,
                                    `[${selectedIndices['domainIndex']}].value`,
                                    ''
                                  )
                                );
                              } else if (event.key === 'ArrowDown') {
                                event.preventDefault();
                                setSelectedIndices((prevState) => {
                                  const prevIndex = prevState['domainIndex'];
                                  return {
                                    ...prevState,
                                    domainIndex: Math.min(
                                      prevIndex + 1,
                                      filtereddomains.length - 1
                                    ),
                                  };
                                });
                              } else if (event.key === 'ArrowUp') {
                                event.preventDefault();
                                setSelectedIndices((prevState) => {
                                  const prevIndex = prevState['domainIndex'];
                                  return {
                                    ...prevState,
                                    domainIndex: Math.max(prevIndex - 1, 0),
                                  };
                                });
                              }
                            }}
                            autoComplete='off'
                          />
                        </div>
                      </div>

                      <div className={searchListWrapper}>
                        {filtereddomains?.slice(0, 5).map((domain, index) => (
                          <span
                            key={domain.value}
                            className={`${teamDropdownItem} ${
                              selectedIndices['domainIndex'] === index
                                ? highlightedDropdownItem
                                : ''
                            } ${
                              domain.value === getValues('domain')
                                ? selectedBackground
                                : ''
                            }`}
                            onClick={() => {
                              setValue('domain', domain.value);
                              setCurrentDropdownStates('');
                            }}>
                            {domain.name}
                          </span>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
                <div className={row2item2}>
                  <label htmlFor='priorityDomain' className={labelTitle}>
                    Priority
                  </label>
                  <div style={{ position: 'relative' }}>
                    <input
                      className={inputFieldWrap}
                      id='priorityDomain'
                      {...register('priorityDomain')}
                      placeholder='Select'
                      readOnly
                      onClick={() => {
                        setSelectedIndices((prevState) => ({
                          ...prevState,
                          priorityDomainIndex: 0,
                        }));
                        setCurrentDropdownStates((prevState) =>
                          prevState === 'priorityDomain' ? '' : 'priorityDomain'
                        );
                      }}
                    />
                    <img
                      src={iconPointDown}
                      alt='pointDown'
                      style={{
                        position: 'absolute',
                        right: '1.6rem',
                        top: '50%',
                        transform: 'translateY(-50%)',
                      }}
                    />
                    <DropdownComponent
                      currentDropdownState={currentDropdownStates}
                      dropdownRef={dropdownRefs['priorityDomain']['ref']}
                      items={priorityObj}
                      selectedIndices={selectedIndices}
                      setValue={setValue}
                      setCurrentDropdownState={setCurrentDropdownStates}
                      dropdownStateName='priorityDomain'
                      dropdownId='searchPriorityDomain'
                      indexName='priorityDomainIndex'
                      setSelectedIndices={setSelectedIndices}
                      getValues={getValues}
                    />
                  </div>
                </div>
              </div>

              <div className={row2Wrap + ' ' + marginBottom}>
                <div className={row2item1}>
                  <label htmlFor='collegeTier' className={labelTitle}>
                    Tier of College
                  </label>
                  <div style={{ position: 'relative' }}>
                    <input
                      className={inputFieldWrap}
                      id='collegeTier'
                      {...register('collegeTier')}
                      placeholder='Select Tier of College'
                      onClick={() => {
                        setSelectedIndices((prevState) => ({
                          ...prevState,
                          collegeTierIndex: 0,
                        }));
                        setCurrentDropdownStates((prevState) =>
                          prevState === 'collegeTier' ? '' : 'collegeTier'
                        );
                      }}
                      autoComplete='off'
                    />
                    <img
                      src={iconPointDown}
                      alt='pointDown'
                      style={{
                        position: 'absolute',
                        right: '1.6rem',
                        top: '50%',
                        transform: 'translateY(-50%)',
                      }}
                    />
                    <DropdownComponent
                      currentDropdownState={currentDropdownStates}
                      dropdownRef={dropdownRefs['collegeTier']['ref']}
                      items={collegeTiers}
                      selectedIndices={selectedIndices}
                      setValue={setValue}
                      setCurrentDropdownState={setCurrentDropdownStates}
                      dropdownStateName='collegeTier'
                      dropdownId='searchCollegeTier'
                      indexName='collegeTierIndex'
                      setSelectedIndices={setSelectedIndices}
                      getValues={getValues}
                    />
                  </div>

                  {errors.role && <span>This field is required</span>}
                </div>
                <div className={row2item2}>
                  <label htmlFor='priorityCollegeTier' className={labelTitle}>
                    Priority
                  </label>
                  <div style={{ position: 'relative' }}>
                    <input
                      className={inputFieldWrap}
                      id='priorityCollegeTier'
                      {...register('priorityCollegeTier')}
                      placeholder='Select'
                      readOnly
                      onClick={() => {
                        setSelectedIndices((prevState) => ({
                          ...prevState,
                          priorityCollegeTierIndex: 0,
                        }));
                        setCurrentDropdownStates((prevState) =>
                          prevState === 'priorityCollegeTier'
                            ? ''
                            : 'priorityCollegeTier'
                        );
                      }}
                    />
                    <img
                      src={iconPointDown}
                      alt='pointDown'
                      style={{
                        position: 'absolute',
                        right: '1.6rem',
                        top: '50%',
                        transform: 'translateY(-50%)',
                      }}
                    />
                    <DropdownComponent
                      currentDropdownState={currentDropdownStates}
                      dropdownRef={dropdownRefs['priorityCollegeTier']['ref']}
                      items={priorityObj}
                      selectedIndices={selectedIndices}
                      setValue={setValue}
                      setCurrentDropdownState={setCurrentDropdownStates}
                      dropdownStateName='priorityCollegeTier'
                      dropdownId='searchPriorityCollegeTier'
                      indexName='priorityCollegeTierIndex'
                      setSelectedIndices={setSelectedIndices}
                      getValues={getValues}
                    />
                  </div>
                </div>
              </div>

              <div className={row2Wrap}>
                <div className={row2item1}>
                  <label htmlFor='noticePEriodMax' className={labelTitle}>
                    Notice Period (in days)
                  </label>
                  <div style={{ position: 'relative' }}>
                    <input
                      className={inputFieldWrap}
                      id='noticePEriodMax'
                      {...register('noticePEriodMax')}
                      placeholder='Enter Max. Notice Period'
                      onClick={() => {
                        setSelectedIndices((prevState) => ({
                          ...prevState,
                          noticePEriodMaxIndex: 0,
                        }));
                        setCurrentDropdownStates((prevState) =>
                          prevState === 'noticePEriodMax'
                            ? ''
                            : 'noticePEriodMax'
                        );
                      }}
                    />
                    <span
                      style={{
                        position: 'absolute',
                        right: '1.6rem',
                        top: '50%',
                        transform: 'translateY(-50%)',
                      }}>
                      <img
                        style={{
                          marginRight: '1.2rem',
                          cursor: 'pointer',
                        }}
                        src={iconMinus}
                        alt='pointDown'
                        onClick={() => handleDecrease('noticePEriodMax')}
                      />

                      <img
                        style={{
                          cursor: 'pointer',
                        }}
                        src={iconPlus}
                        alt='pointDown'
                        onClick={() => handleIncrease('noticePEriodMax')}
                      />
                    </span>
                  </div>

                  {errors.role && <span>This field is required</span>}
                </div>
                <div className={row2item2}>
                  <label
                    htmlFor='priorityNoticePeriodMax'
                    className={labelTitle}>
                    Priority
                  </label>
                  <div style={{ position: 'relative' }}>
                    <input
                      className={inputFieldWrap}
                      id='priorityNoticePeriodMax'
                      {...register('priorityNoticePeriodMax')}
                      placeholder='Select'
                      readOnly
                      onClick={() => {
                        setSelectedIndices((prevState) => ({
                          ...prevState,
                          priorityNoticePeriodMaxIndex: 0,
                        }));
                        setCurrentDropdownStates((prevState) =>
                          prevState === 'priorityNoticePeriodMax'
                            ? ''
                            : 'priorityNoticePeriodMax'
                        );
                      }}
                    />
                    <img
                      src={iconPointDown}
                      alt='pointDown'
                      style={{
                        position: 'absolute',
                        right: '1.6rem',
                        top: '50%',
                        transform: 'translateY(-50%)',
                      }}
                    />
                    <DropdownComponent
                      currentDropdownState={currentDropdownStates}
                      dropdownRef={
                        dropdownRefs['priorityNoticePeriodMax']['ref']
                      }
                      items={priorityObj}
                      selectedIndices={selectedIndices}
                      setValue={setValue}
                      setCurrentDropdownState={setCurrentDropdownStates}
                      dropdownStateName='priorityNoticePeriodMax'
                      dropdownId='searchPriorityNoticePeriodMax'
                      indexName='priorityNoticePeriodMaxIndex'
                      setSelectedIndices={setSelectedIndices}
                      getValues={getValues}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={columnTwo}>
            <h3 className={mainTitle}>Job Description</h3>
            <div className={columnTwoInner}>
              <div className={columnTwoRow1}>
                <div className={row2item2 + ' ' + extraMasala}>
                  <label htmlFor='roleOverview' className={labelTitle}>
                    Role overview and skills required
                  </label>
                  <div style={{ position: 'relative' }}>
                    <input
                      className={inputFieldWrap}
                      id='priorityJobResp'
                      {...register('priorityJobResp')}
                      placeholder='Select priority'
                      readOnly
                      onClick={() => {
                        setSelectedIndices((prevState) => ({
                          ...prevState,
                          priorityJobRespIndex: 0,
                        }));
                        setCurrentDropdownStates((prevState) =>
                          prevState === 'priorityJobResp'
                            ? ''
                            : 'priorityJobResp'
                        );
                      }}
                      autoComplete='off'
                    />
                    <img
                      src={iconPointDown}
                      alt='pointDown'
                      style={{
                        position: 'absolute',
                        right: '1.6rem',
                        top: '50%',
                        transform: 'translateY(-50%)',
                      }}
                    />
                    <DropdownComponent
                      currentDropdownState={currentDropdownStates}
                      dropdownRef={dropdownRefs['priorityJobResp']['ref']}
                      items={jd}
                      selectedIndices={selectedIndices}
                      setValue={setValue}
                      setCurrentDropdownState={setCurrentDropdownStates}
                      dropdownStateName='priorityJobResp'
                      dropdownId='searchPriorityJobResp'
                      indexName='priorityJobRespIndex'
                      setSelectedIndices={setSelectedIndices}
                      getValues={getValues}
                    />
                  </div>
                </div>
                <textarea
                  className={columnTwoTextArea}
                  id='roleOverview'
                  {...register('job_description')}
                  placeholder='Write something about the role'
                />
              </div>
              <div className={columnTwoRow2}>
                <div className={row2item2 + ' ' + extraMasala}>
                  <label htmlFor='keyResponsibilities' className={labelTitle}>
                    Key responsibilities
                  </label>
                  <div style={{ position: 'relative' }}>
                    <input
                      className={inputFieldWrap}
                      id='priorityKeyRole'
                      {...register('priorityKeyRole')}
                      placeholder='Select priority'
                      readOnly
                      onClick={() => {
                        setSelectedIndices((prevState) => ({
                          ...prevState,
                          priorityKeyRoleIndex: 0,
                        }));
                        setCurrentDropdownStates((prevState) =>
                          prevState === 'priorityKeyRole'
                            ? ''
                            : 'priorityKeyRole'
                        );
                      }}
                      autoComplete='off'
                    />
                    <img
                      src={iconPointDown}
                      alt='pointDown'
                      style={{
                        position: 'absolute',
                        right: '1.6rem',
                        top: '50%',
                        transform: 'translateY(-50%)',
                      }}
                    />
                    <DropdownComponent
                      currentDropdownState={currentDropdownStates}
                      dropdownRef={dropdownRefs['priorityKeyRole']['ref']}
                      items={jd}
                      selectedIndices={selectedIndices}
                      setValue={setValue}
                      setCurrentDropdownState={setCurrentDropdownStates}
                      dropdownStateName='priorityKeyRole'
                      dropdownId='searchPriorityKeyRole'
                      indexName='priorityKeyRoleIndex'
                      setSelectedIndices={setSelectedIndices}
                      getValues={getValues}
                    />
                  </div>
                </div>

                <textarea
                  className={columnTwoTextArea}
                  id='keyResponsibilities'
                  {...register('kra_okr')}
                  placeholder='Write something about the role'
                />
              </div>
            </div>
          </div>
          <div className={submitButton}>
            <button
              type='button'
              className={btnCancel}
              onClick={() => navigate('/client/dashboard')}>
              Cancel
            </button>
            <button className={btnSubmit} type='submit'>
              Save and Post Job
            </button>
          </div>
        </form>
      </div>
      <SubmitModal />
    </section>
  );
};

export default CreateJob;
