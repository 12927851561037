import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { get } from 'lodash';

import styles from './SideNav.module.css';
import iconHome from '../../assets/icon-home.svg';
import iconJob from '../../assets/icon-job.svg';
import iconCandidate from '../../assets/icon-candidate.svg';
import { iconBell, iconSetting, iconLogout } from '../../assets';

const SideNav = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { data } = useSelector((state) => state.dashboard);
  const { data: jobPostingData } = useSelector((state) => state.createJob);

  return (
    <nav className={styles.dashboardSidebar}>
      <div className={styles.dashboardSidebarHead}>
        <span className={styles.smallText}>company</span>
        <span className={styles.textBig}>
          {get(data, 'company_name', '') ||
            get(jobPostingData, 'company_name', '')}
        </span>
      </div>
      <div className={styles.dashboardRouteWrap}>
        <div>
          <div
            className={`${styles.dashboardRouteItem} ${
              location.pathname === '/client/dashboard' ? styles.bgGreen : ''
            }`}
            onClick={() => navigate('/client/dashboard')}>
            <span className={styles.dashboardIconBox}>
              <img src={iconHome} alt='iconHome' />
            </span>
            <span className={styles.navRouteText}>home</span>
          </div>
          <div
            className={`${styles.dashboardRouteItem} ${
              location.pathname === '/client/jobs' ? styles.bgGreen : ''
            }`}
            onClick={() => navigate('/client/jobs')}>
            <span className={styles.dashboardIconBox}>
              <img src={iconJob} alt='iconJob' />
            </span>
            <span className={styles.navRouteText}>jobs</span>
          </div>
          <div className={styles.dashboardRouteItem}>
            <span className={styles.dashboardIconBox}>
              <img src={iconCandidate} alt='iconJob' />
            </span>
            <span className={styles.navRouteText}>candidates</span>
          </div>
        </div>
        <div>
          <div
            className={styles.dashboardRouteItem}
            style={{ visibility: 'hidden' }}>
            <span className={styles.dashboardIconBox}>
              <img src={iconBell} alt='iconBell' />
            </span>
            <span className={styles.navRouteText}>notifications</span>
          </div>
          <div
            className={styles.dashboardRouteItem}
            style={{ visibility: 'hidden' }}>
            <span className={styles.dashboardIconBox}>
              <img src={iconSetting} alt='iconSetting' />
            </span>
            <span className={styles.navRouteText}>settings</span>
          </div>
          <div
            className={styles.dashboardRouteItem}
            onClick={() => {
              window.location = '/logout';
            }}>
            <span className={styles.dashboardIconBox}>
              <img src={iconLogout} alt='iconLogout' />
            </span>
            <span className={styles.navRouteText}>logout</span>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default SideNav;
